.AutomationContainer .info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}

.AutomationContainer .info-container {
    background-color: #f0f0eb !important;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}

.AutomationContaine .info-container:nth-child(1) {
    margin-bottom: 2rem;
}

.AutomationContainer .info-container h4 {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 18.75px;
}