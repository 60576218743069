.document-category-label {
    border-top: 2px solid #ccc;
    padding-top: 10px;
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
}

.data-field {
    margin-bottom: 0.5rem;
}

.upload-section {
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}

.dropzone {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 40px;
    text-align: center;
    background: #f9f9f9;
    cursor: pointer;
    transition: background 0.3s ease;
}

.dropzone.dragging {
    background: #e0f7fa;
    border-color: #00acc1;
}

.upload-icon {
    color: #00acc1;
    margin-bottom: 10px;
}

.file-input {
    display: none;
}

.file-list {
    list-style-type: none;
    padding: 0;
    margin-top: 15px;
}

.file-item {
    background: #fff;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: block;
    align-items: center;
}

.file-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 3px;
}
.file-name-delete {
    display: flex;
    align-items: center;
}

.file-name {
    flex: 1;
}

.delete-icon {
    color: red;
    cursor: pointer;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.delete-icon:hover {
    color: darkred;
}

.loading-bar-footer {
    margin-bottom: 5px;
}