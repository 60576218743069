.customer-handle-modal.modal-dialog {
    max-width: 800px !important;
}
.customer-handle-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.customer-handle-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.customer-handle-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.customer-handle-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.customer-handle-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}