@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");


.car-document-modal {
    font-family: "Poppins" !important;
    min-width: 1300px;
}

.car-document-modal .modal-body {
    min-height: 33vh;
}
.car-document-modal .modal-body {
    border-radius: 0px !important;
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}
.car-document-modal .modal-content .btn {
    border-radius: 13px;
}
.car-document-modal .modal-header {
    justify-content: center;
    text-transform: uppercase;
}
.car-document-modal .card-body {
    text-align: center;
    padding: 0;
}

.car-document-modal .doc-toggle-btn {
    width: 100%;
    font-size: 80%;
    font-weight: 600;
    margin: 0.5rem 0px;
    border-radius: 0px !important;
    border: none;
    color: black;
    background-color: rgb(213, 236, 255);
}
.car-document-modal .doc-toggle-btn.deleted {
    background-color: rgb(255 213 213);
}
.car-document-modal .doc-toggle-btn:hover,
.car-document-modal .doc-toggle-btn.active {
    background-color: rgb(0, 140, 255) !important;
}
.car-document-modal .doc-toggle-btn.deleted:hover,
.car-document-modal .doc-toggle-btn.deleted.active {
    background-color: rgb(255, 64, 64) !important;
}
.car-document-modal .card-body .image-details-wrapper {
    margin-top: 1rem;
    border-top: 2px solid rgb(196, 196, 196);
    background-color: aliceblue;
    text-align: left;
    padding: 0.5rem;
}
.car-document-modal .image-details-wrapper label {
    font-size: 80%;
    font-weight: 600;
    margin-bottom: 0px;
    display: block;
}
.car-document-modal .image-details-wrapper small {
    display: block;
    padding-bottom: 3px !important;
}
.car-document-modal .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-document-modal .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.car-document-modal .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.DocViewerModalBody {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

}

.DocViewerImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;

}

.IdnModalBody {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    flex-direction: column;

}

.IdnModalInfoTitle {
    margin-top: 1rem;
    text-align: left;
}

.IdnModalInfo {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 3rem;

}
.IdnModalInfoItem {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.IdnModalInfoItem Label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0px;
}

.IdnPdfViewer {
    margin-top: 10rem;
}

.CustomerIdentifierRow:hover {
    background: #e7e7e7e1;
}

.CustomerIdentifierRow {
    align-items: center;
    justify-content: center;
    margin: auto;
}

.CustomerIdentifierContainer {
    max-height: 50%;
    min-height: 200px;
    overflow-y: scroll;
}
