@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.CarIssueModal {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
}
.CarIssueModal .modal-content {
    background-color: transparent;
}
.CarIssueModal .nav-tabs {
    border-bottom: 1px solid #e2e8ed;
}
.CarIssueModal .nav.nav-tabs .nav-link.active {
    background-color: #344A5C;
    color: #ffffff;
}

.CarIssueModal .modal-header {
    font-weight: 500;
    background-color: #344A5C;
    color: #ffffff;
}
.CarIssueModal .btn-link {
    color: #344A5C;
}
.CarIssueModal .modal-header a {
    color: #fff;
}
.CarIssueModal .modal-header .phone {
    padding-left: 20px;
}
.CarIssueModal .modal-body {
    background-color: #fff;
}
.CarIssueModal .modal-footer {
    background-color: #fff;
}
.CarIssueModal .row {
    border: 1px solid #91a8bb78;
    border-radius: 5px;
    margin: 10px;
}
.CarIssueModal .card-title {
    margin-bottom: 0.2rem;
    font-size: .75em;
    opacity: .6;
}
.CarIssueModal .danger.row {
    border: 2px solid #ff2f2fab;
}
.CarIssueModal .danger .card-title {
    color: #273846cc;
    opacity: .8;
}
.CarIssueModal .card-body {
    padding: 0.9rem;
}
.CarIssueModal .card {
    border: none;
}
.CarIssueModal a, .CarIssueModal a:hover, .CarIssueModal a:link, .CarIssueModal a:visited {
    text-decoration: none;
}