.payment-schedule-modal-primary-btn {
    background-color: #1B4D7E;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.payment-schedule-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.payment-schedule-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

.payment-schedule-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.payment-schedule-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.payment-schedule-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.payment-schedule-modal .modal-content small,
.payment-schedule-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.payment-schedule-modal .modal-content .modal-header {
    justify-content: center;
}
.payment-schedule-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}