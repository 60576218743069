.argyle-integration #argyle-logo {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.argyle-integration .card-header {
    background-color: rgb(49, 58, 73);
    border-radius: 10px 10px 0 0;
}

.argyle-integration .card {
    border-radius: 10px;
}

.argyle-integration .btn {
    padding: 8px 20px;
    margin-right: 15px;
    font-size: 16px;
    border: none;
}