.bos-listing-container .table a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent;
}
.bos-listing-container .table a:hover {
	color: #0056b3;
	text-decoration: underline;
}
.bos-create-branch-modal {
	min-width: 60%;
}
.members-options-wrapper {
	max-height: 20vh;
	overflow-y: scroll;
}
.members-options-wrapper::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.members-options-wrapper::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.members-options-wrapper::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}

.new-branch-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.new-branch-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.new-branch-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}
.missing-required-settings-tooltip {
	opacity: 1 !important;
}
.missing-required-settings-tooltip .tooltip-inner {
	min-width: 175px;
	max-width: 300px;
	padding: 0.25rem 1rem 0.5rem 1rem;
	width: auto;
	text-align: left;
	background-color: #F2F2F2;
	color: black;
	border: 1px solid #d7dce2;
	max-height: 200px;
	overflow: auto;
}
.missing-required-settings-tooltip label {
	display: block;
	text-align: center;
	font-size: 1em;
	text-transform: uppercase;
	font-weight: bold;
}
.missing-required-settings-tooltip ul {
	padding-left: 1rem;
	padding-bottom: 0px;
}