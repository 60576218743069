.pdf-container {
    background-color: rgba(48, 49, 48, 0.42);
    z-index: 1000;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 1.3s;
    width: 100%;
}

.pdf-container .pdf-canvas {
    width: 70%;
    height: 100%;
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    left: 10%;
}

.pdf-container .close {
    position: absolute;
    top: 15px;
    right: 20px;
    color: white
}

.application-form a {
    color: #ffffff;
    text-decoration: none;
}

.pdf-container .pagination p {
    color: white;
    margin: 0 !important;
}

.pdf-container .pagination button {
    color: #4338CA;
}