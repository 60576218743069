.bos-attributes-listing-table-wrapper{
    /* max-height: 65vh;
    overflow-y: auto; */
    overflow-x: hidden;
}
.bos-attributes-listing-table-wrapper table thead th{
    color: black !important;
}
.bos-attributes-listing-table-wrapper::-webkit-scrollbar{
	width: 5px;
	height: 5px;
}
.bos-attributes-listing-table-wrapper::-webkit-scrollbar-track{
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
   
.bos-attributes-listing-table-wrapper::-webkit-scrollbar-thumb{
	background-color: #303e67;
	outline: 1px solid #303e67;
}