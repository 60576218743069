@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.filter-container {
    background-color: white;
    border-radius: 10px;
    width: 100%;
}

.filter-container .date-picker {
    width: 100%;
    color: #303e67 !important;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    padding: 1.5rem;
}

.display-filter {
    color: #303E67 !important;
    text-decoration: underline !important;
    text-transform: uppercase;
}

.display-filter:hover {
    cursor: pointer
}


.waveform-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    background: transparent;
}

.waveform-container .play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 30px;
    background: #06c;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 3px;
}

.waveform-container .play-button i {
    color: #fff;
}

.waveform-container .wave {
    width: 100%;
    height: 60px;
}

.attachment-viewer-modal {
    max-width: 80%;
}
.attachment-viewer-modal .modal-body {
    text-align: center;
}










.bos-interaction-wrapper {
    /* font-family: "Poppins"; */
    font-size: .8em;
    max-height: 45rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0rem 1.25rem 0rem 1.25rem;
    font-size: 12px;
}
.bos-interaction-wrapper::-webkit-scrollbar {
    width: 10px;
}

.bos-interaction-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f2f3f59c;
}

.bos-interaction-wrapper::-webkit-scrollbar-thumb {
    background-color: #92b1ff;
    margin: 5px;
    outline: 1px solid #92b1ff;
    border-radius: 10px;
}
.bos-interaction-wrapper p {
    margin-bottom: 0.25rem;
    word-wrap: break-word;
    white-space: pre-line
}
.bos-interaction-wrapper a {
    display: block;
    margin-bottom: 0.5rem;
}
.bos-interaction-wrapper label {
    font-weight: 500;
    margin: 0;
}
.bos-interaction-wrapper .inbound-interaction .interaction-type-icon {
    float: left;
}
.bos-interaction-wrapper .outbound-interaction .interaction-type-icon {
    float: right;
}
.bos-interaction-wrapper .interaction-type-icon {
    text-align: center;
    padding: 0.35rem 0.5rem;
    display: inline-block;
    width: 5%;
}
.bos-interaction-wrapper .interaction-type-icon i {
    font-size: 18px;
    cursor: default;
}
.bos-interaction-wrapper .interaction-date {
    background-color: #f3773d3d;
    border: 1px solid #f3773d51;
    color: black;
    margin-bottom: 0.5rem;
    border-radius: 7px;
    text-align: center;
}
.bos-interaction-wrapper .interaction {
    display: flow-root;
}
.bos-interaction-wrapper .interaction-details-wrapper {
    width: 95%;
    display: inline-block;
}
.bos-interaction-wrapper .interaction-details {
    padding: 0.35rem 1rem 0rem 1rem;
    border-radius: 13px;
    min-width: 200px;
}
.bos-interaction-wrapper .interaction-details span {
    display: inline-block;
}
.bos-interaction-wrapper span.interaction-time {
    width: 100%;
    font-size: 10px;
    display: flow-root;
}
.bos-interaction-wrapper span.interaction-time p {
    margin: 0;
}
.bos-interaction-wrapper .outbound-interaction span.interaction-time p {
    float: right;
}
.bos-interaction-wrapper .interaction-details span.interaction-files {
    min-width: 150px;
}
.bos-interaction-wrapper .interaction-details span.interaction-user {
    min-width: 120px;
    font-size: 11px;
}
.bos-interaction-wrapper .interaction-details span.interaction-msg {
    display: block;
    width: 100%;
    max-width: 400px;
    color: black;
}
/* .bos-interaction-wrapper .inbound-interaction .interaction-type-icon{
    color: rgb(0, 255, 0);
}
.bos-interaction-wrapper .outbound-interaction .interaction-type-icon{
    color: #0048ff;
} */
.bos-interaction-wrapper .inbound-interaction .interaction-details {
    background-color: rgb(191 240 191 / 45%);
    float: left;
    border-top-left-radius: 0px;
}
.bos-interaction-wrapper .outbound-interaction .interaction-details {
    background-color: #004aff1f;
    border-top-right-radius: 0px;
    float: right;
}
.bos-interaction-wrapper .office-interaction .interaction-type-icon {
    color: #ceb600;
}
.bos-interaction-wrapper .office-interaction .interaction-details {
    background-color: #eedd5b;
}