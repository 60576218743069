.charge-driver-modal.modal-dialog {
	max-width: 800px !important;
    border-radius: 0px !important;
    padding: 0px !important;
}
.charge-driver-modal .modal-content {
	background: #FFF;
	color: #525f6f;
    /* padding: 0px !important; */
}
.charge-driver-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}
​
.charge-driver-modal .modal-content .modal-header {
    background-color: #1B4D7E !important;
    color: green !important;
    font-size: large !important;
}
.charge-driver-modal-primary-btn {
	background-color: #1B4D7E;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.charge-driver-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.charge-driver-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}