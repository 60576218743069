.TaskList .task-cell {
    min-width: 70px;
    max-width: 70px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TaskList .task-cell.title {
    max-width: 120px;
}

.TaskList .dropdown-menu {
    margin: 0;
    padding: 0;
    text-align: center;
}

.TaskList .dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
}

/* Give border radius to first and last item */
.TaskList .dropdown-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.TaskList .dropdown-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.popover-custom-width.popover {
    width: 100% !important;
}