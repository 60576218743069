.arrow-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.arrow-btn {
    background-color: #303e67;
    color: white;
}
.overview-primary-btn {
    background-color: #303e67;
    color: white;
    border-radius: 5px;
}