.mass-message-btn {
	background-color: #0B80F9;
	border: 1px solid #0B80F9;
	border-radius: 7px;
}

.mass-message-btn:hover {
	color: #41505d;
	background-color: transparent;
	border: 1px solid #41505d;
}

.mass-message-modal.modal-dialog {
	max-width: 45% !important;
}
.mass-message-modal .btn {
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400 !important;
}

.mass-message-modal .btn.btn-primary {
	background-color: #0B80F9;
}

.mass-message-modal .modal-content {
	background-color: white;
	border-radius: 0px;
	color: black;
}

.mass-message-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.mass-message-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}

.mass-message-modal .modal-content small,
.mass-message-modal .modal-content label {
	color: rgb(73, 73, 73);
	text-transform: uppercase;
	letter-spacing: 0.75px;
}

.mass-message-modal .modal-content .modal-header {
	justify-content: center;
}

.mass-message-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card {
	margin: 7px 0px;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card-body {
	padding: 7px 15px;
	background-color: #0000000e;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
	padding-top: 0.45rem;
}

.mass-message-modal .modal-content .issue-modal-jobs-wrapper label {
	margin-bottom: 0;
}

.mass-message-modal .modal-content .issue-modal-collapse-btn {
	width: 100%;
	border-radius: 0px;
	text-transform: uppercase;
	background-color: #0000008c;
	border: none;
}

.mass-message-modal .issue-extra-info-wrapper {
	margin-top: 1.5rem;
	padding-top: 1.25rem;
	border-top: 1px solid #dee2e6;
}


.mass-message-modal .toggle-switch {
	position: relative;
	width: 60px;
	padding: 0px 9px;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: left;
}
.mass-message-modal .toggle-switch-checkbox {
	display: none;
}
.mass-message-modal .toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #bbb;
	border-radius: 20px;
	margin: 0;
}
.mass-message-modal .toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.1s ease-in 0s;
}
.mass-message-modal .toggle-switch-inner:before, .toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 24px;
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	box-sizing: border-box;
}
.mass-message-modal .toggle-switch-inner:before {
	content: "";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: coral;
	color: #fff;
}
.mass-message-modal .toggle-switch-disabled {
	background-color: #ddd;
	cursor: not-allowed;
}
.mass-message-modal .toggle-switch-disabled:before {
	background-color: #ddd;
	cursor: not-allowed;
}
.mass-message-modal .toggle-switch-inner:after {
	content: "";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #2acd72;
	color: #fff;
	text-align: right;
}
.mass-message-modal .toggle-switch-switch {
	display: block;
	width: 18px;
	margin: 3px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 25px;
	border: 0 solid #bbb;
	border-radius: 20px;
	transition: all 0.1s ease-in 0s;
}
.mass-message-modal .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.mass-message-modal .toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 10px;
}