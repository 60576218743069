.Add-driver-modal.modal-dialog {
	max-width: 800px !important;
}
.Add-driver-modal .modal-content {
	background-color: white;
	color: #525f6f;
}
/* .Add-driver-modal .modal-content .modal-body {
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
} */
.Add-driver-modal .modal-content .modal-body::-webkit-scrollbar {
	width: 5px;
	height: 5px;
	/* display: none; */
}
.Add-driver-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
	/* display: none; */
}

.Add-driver-modal .modal-content .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.Add-driver-modal .modal-content .toggle-section-heading {
	display: block;
	color: #525f6f;
	background-color: #D6D6D6;
	border-radius: 10px;
	padding: 0.5rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}

.Add-driver-modal .modal-content .toggle-section-heading.active {
	/* background-color: #1A1C1E; */
	background-color: #303e67;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.Add-driver-modal .modal-content label {
	color: #525f6f;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-size: 80%;
	font-weight: 600;
	margin-bottom: 0.25rem;
}
.Add-driver-modal .modal-content p {
	margin-bottom: 0.25rem;

}
.Add-driver-modal.modal-dialog row {
	margin-bottom: 15px !important;
}

.Add-driver-modal .modal-content .modal-header {
	justify-content: center;
}

.Add-driver-modal .modal-content .modal-header .modal-title {
	font-weight: 400;
	font-size: 1.75em;
	text-transform: uppercase;
	/* color: white; */
}
.Add-driver-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}

.Add-driver-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.Add-driver-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}
.Add-driver-modal-danger-btn {
	background-color: red;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: #ffffff;
}