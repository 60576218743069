.confirmation-popup {
    border: solid 10px white !important;
    border-radius: 5px !important;
    background-color: white;
}
.confirmation-popup .modal-content {
    padding: 0px !important;
    background-color: white;

}
.confirmation-popup .modal-header {
    text-transform: uppercase;
    border-radius: 5px;
    color: #303e67;
}
.confirmation-popup .modal-footer .primary-btn {
    background-color: #303e67 !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: white !important;
    cursor: pointer !important;
    height: 35px !important;
    width: 60px !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-footer .primary-action-btn {
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    height: 35px !important;
    color: white !important;
    cursor: pointer !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-footer .secondary-btn {
    background-color: white !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
    cursor: pointer !important;
    height: 35px !important;
    width: 60px !important;
    text-transform: uppercase;
}
.confirmation-popup .modal-body p {
    font-size: 15px !important;
    font-weight: 500 !important;
    text-align: left;
}