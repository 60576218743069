.TaskDetailsSidePanelContainer {
    position: relative;
    font-size: 1.0em;
}
.TaskDetailsSidePanel {
    -webkit-box-shadow: -8px 10px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -8px 10px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: -8px 10px 5px 0px rgba(0, 0, 0, 0.16);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 20px 20px;
    width: 50%;
    height: 100%;
    margin-right: -100%;
    background-color: #f8f9fa;
    border-right: 1px solid #e9ecef;
    z-index: 1000;
    opacity: 0;
    transform: translate3d(200px, 0, 0);
    transition: all 150ms cubic-bezier(0, 0, 0.35, 1);
}

.TaskDetailsSidePanel.show {
    opacity: 1;
    margin-right: 0%;
    transform: translate3d(0, 0, 0);
    overflow: scroll;
}

.CommentCard .card-header, .CommentCard .card-footer {
    background-color: #F8F9FA;
    border-bottom: none;
    border-top: none;
    font-size: 1.0em;
}

.CommentCard .card-header {
    font-style: italic;

}

.CommentCard .card-body {
    background-color: #FFFFFF;
    font-size: 1.0em;
}

.CommentCard .like .liked {
    text-shadow: 0 0 0 #392c00;
    color: #ffb62c;
}
.NewCommentForm {
    font-size: 1.0em;
}
.TaskDetailsSidePanel .tab-content {
    padding-top: 20px;
    padding-bottom: 20px;
}

.NewCommentTextAreaFormGroup .MentionsInputContainer {
    position: relative;
}
.NewCommentTextAreaFormGroup .text-area--multiLine {
    position: relative;
    min-height: 100px;
    background-color: #FFFFFF;
}

.NewCommentTextAreaFormGroup .text-area__input {
    min-width: 100%;
    max-width: 100%;
    resize: auto !important;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.NewCommentTextAreaFormGroup .text-area__input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.NewCommentTextAreaFormGroup .user-suggestion {
    background-color: #f3f5f7;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px;
    min-width: 190px;
}