.dnr-modal-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.dnr-modal-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.dnr-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.dnr-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.dnr-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.dnr-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.dnr-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.dnr-modal .modal-content small,
.dnr-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.dnr-modal .modal-content .modal-header {
    justify-content: center;
}
.dnr-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.dnr-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}