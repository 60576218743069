/* .datetime-renderer #custom-tooltip-top .conversion-table td, */
#datetime-conversion-tooltip{
    min-width: 275px !important;
    background-color: #fff;
    color: black;
    padding: 0 !important;
    margin: 0 !important;
    border: 1px solid #d7dce2;
    font-size: 80% !important;
}
#datetime-conversion-tooltip table{
    width: 100%;
    text-align: left !important;
    margin: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
#datetime-conversion-tooltip table th{
    background-color: #d7dce2 !important;
}
#datetime-conversion-tooltip table tr{
    border-bottom: 1px solid #d7dce2;
}
#datetime-conversion-tooltip table th,
#datetime-conversion-tooltip table td{
    padding-left:10px;
    padding-right:10px;
    padding-top: 5px;
    padding-bottom: 5px;
}