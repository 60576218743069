@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body, html {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: 'Rubik', 'San serif' !important;
  color: #525f7f !important;
  background-color: #f2f3f59c !important;
  overflow-x: hidden;
}
.bos-bg-blue-light {
  background-color: #0066cc !important;
}
.bos-bg-blue {
  background-color: #3e4c6d !important;
  border-color: #3e4c6d !important;
}
.bos-bg-black {
  background-color: #1a1c1e !important;
}
.badge {
  font-weight: 500 !important;
  background-color: #3e4c6d;
  color: white;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-primary {
  color: #fff;
  background-color: #3e4c6d;
}
.badge-blue {
  color: #fff;
  background-color: blue;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
b, strong {
  font-weight: 500 !important;
}
.bos-left-nav.show {
  font-family: 'Poppins', 'Rubik', 'San serif' !important;
  display: inline-block;
  position: fixed;
  top: 0;
  background-color: #1A1C1E;
  padding-top: 40px;
  padding-right: 60px;
  height: 100vh;
  z-index: 1;
  box-shadow: rgb(50 50 93 / 10%) 15px 0px 20px;
  overflow: scroll;
}
.bos-left-nav.show .bos-logo-item {
  margin-left: -9px;
  margin-right: -9px;
  text-align: center;
  border-radius: 0px;
  background-color: #ffffff52;
  padding-top: 0.5rem;
}
.bos-btn-primary {
  background-color: #0B80F9 !important;
  border: 1px solid #0B80F9 !important;
  border-radius: 7px !important;
  color: white !important;
}
.bos-btn-primary:hover {
  color: #41505d !important;
  background-color: transparent !important;
  border: 1px solid #41505d !important;
}
.color-blue-neon {
  color: #4D4EFF !important;
}
.color-blue {
  color: #0066cc !important
}
.bos-left-nav {
  display: none;
}
.bos-left-nav .bos-left-nav-footer {
  bottom: 0px;
  position: absolute;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 17px;
  padding-right: 33px;
}
.bos-left-nav .bos-left-nav-footer a:active,
.bos-left-nav .bos-left-nav-footer a:visited,
.bos-left-nav .bos-left-nav-footer a {
  color: #0066cc !important;
  text-decoration: none;
}
.bos-left-nav .bos-left-nav-footer a:hover {
  color: #fff !important;
  text-decoration: underline;
}
.bos-left-nav .menu-list-item-pin {
  float: right;
  /* padding: 10px 15px; */
  color: #888B90;
  margin-top: 13px;
}
.bos-left-nav .menu-list-item-pin.active-pin {
  color: #007bff;
}
.bos-left-nav .list-item-text {
  margin-left: 0.5rem;
}
.hamburger {
  display: inline-block;
  padding: 10px 50px;
  position: absolute;
  background-color: #FFF;
  z-index: 2;
  border-radius: 0px 0px 13px 0;
}

#root > .mainContent {
  display: inline-block;
  overflow: hidden;
  background-color: #f2f3f59c;
  padding: 30px;
  min-height: 100vh;
}

@media screen and (min-width: 768px) {
  /* For desktop: */
  .bos-left-nav.show {
    height: 100vh;
    display: inline-block;
    width: 15%;
    padding: 0px 0;
    border-right: 1px solid rgb(212 224 232 / 63%);
    overflow: auto;
    background-color: #1A1C1E;
    position: fixed;
  }
  #root > .mainContent {
    margin-left: 15%;
    width: 85%;
  }
  #root > .mainContent.full-width {
    margin-left: 0%;
    width: 100%;

  }
  .hamburger {
    display: none;
  }
}

.bos-left-nav ul {
  list-style-type: none;
  padding: 0px 0.75rem;
  max-height: 90vh;
  overflow-y: auto;
}

.bos-left-nav.show ul::-webkit-scrollbar {
  width: 7px;
}

.bos-left-nav.show ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px #fff;
}

.bos-left-nav.show ul::-webkit-scrollbar-thumb {
  background-color: #f2f3f59c;
  margin: 0px;
  outline: 1px solid #f2f3f59c;
  border-radius: 3px;
}

.bos-left-nav ul label::after {
  content: '';
  display: block;
  position: relative;
  width: 50%;
  border-top: 1px ridge #F3763D;
}
.bos-left-nav ul label.pinned-item-label::after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  border-top: 1px ridge #0066cc;
}
.bos-left-nav ul label.pinned-item-label {
  font-size: 11px;
  display: block;
  text-align: center;
  font-weight: 500;
  letter-spacing: 3px;
}
.bos-left-nav ul label {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 1rem;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  margin: 0px;
  font-size: 10px;
  color: #fff;
  letter-spacing: 0.5px;
}
.bos-left-nav ul li {
  border-radius: 15px;
}
.bos-left-nav li a {
  padding: 0.65rem 0.75rem;
  font-size: 12px;
  display: inline-block;
  letter-spacing: 0.5px;
  color: #888B90;
}
.bos-left-nav a:hover,
.bos-left-nav a:focus {
  text-decoration: none;
  /* font-weight: 500; */
  /* background-color: #303334; */
  color: #FAFAFA;
}

.bos-left-nav li.active a {
  color: #FAFAFA;
}
.bos-left-nav li.active {
  background-color: #303334;
}

.bos-left-nav .sidebar-child-list {
  padding-top: 1rem;
}
.bos-left-nav .sidebar-child-list li a {
  padding: 0.65rem 0rem 0.65rem 3.5rem;
}
.bos-left-nav .sidebar-child-list li.active a {
  background-color: #3894da;
}


#root > .mainContent .mainHeader {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e9eaf359;
  padding-bottom: 25px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.modal-content {
  border: none !important;
}
/* #root > .mainContent .mainHeader .right {
  display: inline-block;
  float: right;
  text-align: right;
  width: 100%;
  color: #525f7f;
  vertical-align: -webkit-baseline-middle;
} */
/* #root > .mainContent .mainHeader span,  */

.mainContent .mainHeader .header-greetings {
  display: inline-block;
  cursor: default;
  text-transform: capitalize;
  margin: 0;
  padding-top: 17px;
}
#root > .mainContent .mainHeader a {
  color: #525f7f;
  text-decoration: none;
  cursor: pointer;
}
/* #root > .mainContent .mainHeader .branch-dropdown-wrapper {
  min-width: 175px;
} */
/* #root > .mainContent .mainHeader .bos-branch-select span {
  display: none;
} */
/* #root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option {
  background-color: #fff;
  color: #212529 !important;
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: .875rem;
} */
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__control {
  background-color: #06c !important;
  color: #fff;
  border: none;
  border-radius: 0;
  min-width: 200px;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__multi-value {
  background-color: transparent;
  border: 1px solid #f2f3f59c;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__multi-value__label,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__single-value,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__value-container,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__input,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__dropdown-indicator,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__placeholder,
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator {
  color: #fff;
  text-align: left;
}

/* #root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator:focus-visible,
#root > .mainContent .mainHeader .bos-branch-select:focus-visible {
  outline: none;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__indicator {
  background-color: #1a1c1e;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option {
  background-color: #fff;
  padding-left: 15px;
  text-align: left;
}
#root > .mainContent .mainHeader .bos-branch-select .bos-branch-select__option:hover {
  background-color: #f8f9fa;
}
 */
@media (min-width: 1200px) {
  .container {
    max-width: 1400px !important;
  }
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.linkHoverEffect {
  position: relative;
  color: #52606b;
  text-decoration: none;
  font-weight: 400;
}
.linkHoverEffect:hover:after {
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.linkHoverEffect:after {
  position: absolute;
  content: "";
  width: 0%;
  margin-top: 4px;
  height: 2px;
  background-color: #52606b;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f3f59c;
}

body::-webkit-scrollbar-thumb {
  background-color: #4f6092;
  margin: 5px;
  outline: 1px solid #4f6092;
  border-radius: 10px;
}
.section-404 {
  background-color: white;
  text-align: center;
  text-transform: uppercase;
  height: 50vh;
  padding-top: 13vh;
  margin-top: 10vh;
  border-radius: 55px;
  box-shadow: 0 0 25px 0px #e7e7e7;
  -webkit-box-shadow: 0 0 25px 0px #e7e7e7;
  -moz-box-shadow: 0 0 25px 0px #e7e7e7;
}
.section-404 p {
  font-size: 72px;
  font-weight: 600;
}
.section-404 .btn {
  border-radius: 20px;
  font-size: 14px;
  padding: 5px 25px;
}
.limit-text {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.limit-text-long {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.bos-badge-blue {
  color: "primary" !important;
  font-size: 12px !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: white !important;
}
.bos-input {
  padding: "5px"
}

.bos-badge-small {
  font-size: 9px !important;
  letter-spacing: 0.25px;
  padding: 0.25em !important;
}

.bos-custom-select .bos-select__dropdown-indicator {
  padding: 4px 8px;
}
.bos-custom-select .bos-select__control {
  border-radius: 7px;
  border-color: hsl(0deg 6% 91%);
  min-height: 33px;
}
.bos-custom-select .bos-select__indicator {
  color: #303e67 !important;
}
.bos-custom-select, .bos-custom-select .bos-select__value-container, .bos-custom-select .bos-select__placeholder {
  font-weight: 500;
  color: #303e67 !important;
  font-size: 13px;
}
.bos-custom-select .bos-select__multi-value {
  border-radius: 3px !important;
}
.bos-custom-select .bos-select__placeholder {
  text-transform: uppercase;
}
.bos-custom-select .bos-select__multi-value__label {
  color: #79829d !important;
  font-size: 12px !important;
}
.bos-custom-select .bos-select__multi-value__label small {
  padding-top: 2px;
}
.bos-custom-select .select-selected {
  background-color: DodgerBlue;
}
.bos-custom-tooltip {
  opacity: 1 !important;
}
.bos-custom-tooltip .tooltip-inner {
  min-width: 175px;
  max-width: 300px;
  padding: 0.25rem 1rem 0.5rem 1rem;
  width: auto;
  text-align: left;
  background-color: #F2F2F2;
  color: black;
  border: 1px solid #d7dce2
}
.bos-custom-tooltip .tooltip-inner label {
  font-size: 1.5em;
  display: block;
  border-bottom: 1px solid #d7dce2;
  text-align: center;
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-header {
  font-size: 1em;
  /* font-weight: 500; */
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-body {
  padding-top: 1rem;
  font-size: 0.85em;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button {
  font-size: 0.95em;
  padding: 0.25rem 1rem;
  background-color: #F2F2F2;
  color: black;
  border: 1px solid #d7dce2
}
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:focus,
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:active,
.bos-custom-tooltip .tooltip-inner .bos-custom-tooltip-footer button:hover {
  background-color: #cacaca;
  color: black;
}
.listing-checkbox-wrapper {
  vertical-align: middle;
  text-align: center;
}
.listing-checkbox-wrapper:hover .listing-checkbox .unchecked,
.listing-checkbox-wrapper .listing-counter {
  display: block;
  font-size: 12px;
}
.listing-checkbox-wrapper:hover .listing-counter,
.listing-checkbox-wrapper .listing-checkbox .unchecked {
  display: none;
}
.bos-custom-link {
  color: inherit !important;
  text-decoration: none;
  font-weight: 500;
}
.bos-custom-link:hover {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.driver-in-office-row {
  background-color: #1a87542e;
  border-bottom: 1px solid white !important;
}
.form-control-file {
  font-size: 1.1em;
  opacity: .8;
}
.dropdown_label {
  display: block;
  margin-bottom: 10px;
}
.dropdown-select {
  padding: 5px;
  display: block;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
}