.insurance-detail-section-primary-btn {
	background-color: #303e67 !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
}
.insurance-detail-section-secondary-btn {
	background-color: white !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: black !important;
	cursor: pointer !important;
}
.insurance-detail-section-danger-btn {
	background-color: red !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
	border: #ffffff !important;
}
.insurance-detail-section-success-btn {
	background-color: #28a745 !important;
	border-radius: 7px !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: white !important;
	cursor: pointer !important;
	border: #ffffff !important;
}