.schedule-return-modal.modal-dialog {
    min-width: 700px !important;
}
.schedule-return-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.schedule-return-modal .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
}
.schedule-return-modal.modal-dialog row {
    margin-bottom: 20px !important;
}
.schedule-return-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.schedule-return-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    opacity: 0.9;
}

.schedule-return-modal-primary-btn:hover {
    background-color: #303e67;
    color: white;
    opacity: 1;
}

.schedule-return-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}