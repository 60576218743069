.merge-driver-modal.modal-dialog {
    max-width: 30% !important;
}
.merge-driver-modal .modal-content {
    background-color: white;
    border-radius: 0px;
}

.merge-driver-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.merge-driver-modal .modal-content small,
.merge-driver-modal .modal-content label {
    color: rgb(73, 73, 73);
    letter-spacing: 0.75px;
}

.merge-driver-modal .modal-content .modal-header {
    justify-content: center;
}

.merge-driver-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.merge-driver-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.merge-driver-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}