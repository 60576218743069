.create-issue-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.create-issue-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.car-issue-modal .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.car-issue-modal .btn.btn-primary {
    background-color: #0B80F9;
}
.car-issue-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.car-issue-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.car-issue-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.car-issue-modal .modal-content small,
.car-issue-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.car-issue-modal .modal-content .modal-header {
    justify-content: center;
}
.car-issue-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card {
    margin: 7px 0px;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card-body {
    padding: 7px 15px;
    background-color: #0000000e;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper .card-body .row {
    padding-top: 0.45rem;
}
.car-issue-modal .modal-content .issue-modal-jobs-wrapper label {
    margin-bottom: 0;
}
.car-issue-modal .modal-content .issue-modal-collapse-btn {
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    background-color: #0000008c;
    border: none;
}
.car-issue-modal .issue-extra-info-wrapper {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid #dee2e6;
}
.work-order {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: right;
}

.work-order:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.work-order:focus {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.WorkOrderModal {
    width: 100%;
    width: 800px !important;
}
.WorkOrderModal .modal-content small,
.WorkOrderModal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.WorkOrderModal .modal-content .modal-header {
    justify-content: center;
}
.WorkOrderModal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
.WorkOrderModal .modal-body {
    background-color: #fff;
}
.WorkOrderModal .modal-footer {
    background-color: #fff;
}
.WorkOrderModal .modal-content .modal-body .small-preview {
    max-height: 100%;
    max-width: 100%;
}
.WorkOrderModal .edit-item {
    position: relative;
}
.WorkOrderModal .edit-item .edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.WorkOrderModal .edit-item .edit-icon {
    position: relative;
    position: absolute;
    right: 10px;
    top: 10px;
}

.work-order-item {
    margin-bottom: 2rem;
}
.add-remove-work-order:last-child {
    margin-left: 1rem;
    align-self: center;
}
.add-remove-work-order {
    align-self: center;
}