.listing-action-items {
    border: none;
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    min-width: 250px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

.listing-action-items ul {
    list-style-type: none;
    padding-left: 0;
}
.listing-action-items ul li {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
}