/* CSS for notes history component */
.notes-history .popover {
    max-width: none !important;
}
.notes-history .popover-inner, .popover {
    min-height: inherit;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.notes-history .popover-header {
    font-size: 15px;
    text-transform: uppercase;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #f0f0eb;
    color: #1B4D7E;
}
.notes-history .main-container {
    display: flex;
    align-items: stretch;
    height: 100%;
}
.notes-history .side-bar {
    max-height: 230px !important;
    width: 300px !important;
    overflow-y: auto;
    padding-right: 5px;
}
.notes-history .more-notes {
    float: right;
    margin-top: 10px !important;
    cursor: pointer !important;
}

.notes-history .toggle-section-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #D6D6D6;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.notes-history .toggle-section-heading.active {
    background-color: #303e67;
    color: #ffffff;
}

.notes-history .left-content {
    display: flex;
    align-items: center;
}

.notes-history .truncate {
    display: inline-block;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notes-history .right-icons {
    margin-left: auto;
    display: flex;
    align-items: center;
}


/* css for notes detail component */
.notes-history-details {
    margin-top: -10px !important;
    margin-left: 7px;
}
.notes-history-details label {
    font-family: 'Poppins';
    font-size: 10px;
    line-height: 5px;
    font-weight: 600;
    font-style: normal;
    cursor: pointer !important;
    text-transform: uppercase;
}
.notes-history-details .btn.primary {
    background-color: #1B4D7E;
    color: #fff;
    border-color: transparent;
    text-transform: uppercase;
    font-size: 10px;
}