.argyle-account-detail-modal .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
    width: 800px;
}
.argyle-account-detail-modal .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
.argyle-account-detail-modal .modal-content .modal-body::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.argyle-account-detail-modal .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.2px;
    font-weight: 400;
}
.argyle-account-detail-modal .modal-content .modal-header {
    justify-content: center;
}
.argyle-account-detail-modal .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.argyle-account-detail-modal-detail-box {
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: #FFFFFF;
    justify-content: space-evenly;
    padding: 2rem;
    border-radius: 10px;
    border-top: solid 0px #C9C9C9;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}