@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');

.ChatBotContainer {
    color: #273846cc;
    font-family: 'Rubik', 'San serif';
    position: relative;
    overflow: hidden;
    min-height: 4100px;
    width: 100%;
}

.ChatBotContainer .btn-outline-primary, .ChatBotContainer .btn-outline-primary {
    color: #52606b;
    border-color: #52606b;
    background-color: transparent;
}
.ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .ChatBotContainer .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ChatBotContainer .btn-outline-primary:hover, .ChatBotContainer .btn-outline-primary:active, .ChatBotContainer .btn-outline-primary:focus,
.ChatBotContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.ChatBotContainer .nav-pills .nav-item {
    margin: 10px;
}
.popover {
    max-width: 500px;
}
.ChatBotContainer .uppercase {
    text-transform: uppercase;
}
.ChatBotContainer .capitalize {
    text-transform: lowercase;
}
.ChatBotContainer .capitalize::first-letter {
    text-transform: capitalize;
}
.ChatBotContainer .linkHoverEffect {
    position: relative;
}
.ChatBotContainer .linkHoverEffect:hover:after {
    width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.ChatBotContainer .linkHoverEffect:after {
    position: absolute;
    content: "";
    width: 0%;
    margin-top: 4px;
    height: 2px;
    background-color: #52606b;
    left: 0;
    bottom: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
i {
    cursor: pointer;
}
.ChatBotContainer .alert {
    text-align: center;
    border: none;
}
.ChatBotContainer .alert-danger {
    background-color: #FF4F4F;
    color: #fff;

}
.ChatBotContainer .alert-primary {
    background-color: #273846cc;
    color: #fff;
}

.ChatBotContainer .table th, .table td {
    vertical-align: middle;
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ChatBotContainer .table tr {
    border-bottom: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
    color: #273846cc;
}
.ChatBotContainer .table th {
    text-transform: uppercase;
    color: rgba(42, 53, 84, 0.52);
    font-size: 0.8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: 0.005em;
}

.shortText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
}

input[type=range] {
    -webkit-appearance: none;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
    background: #273846cc;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: #273846cc;
    border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
    border: 1px solid #273846cc;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}
input[type=range]::-ms-fill-lower {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
    background: #273846cc;
    border-radius: 8px;
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
    box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
    height: 25px;
    width: 25px;
    border-radius: 8px;
    background: #ffffff;
    cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
    background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
    background: #273846cc;
}
.microDashboard card {
    border: none;
}


.ChatBotList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    max-height: 50vh;
    max-width: 100%;
    overflow-y: auto;
}

.ChatBotList li {
    margin: 0 0 10px 0;
    display: block;
    width: 100%;
    display: flex;
    color: #273846cc;
    border-radius: 5px;
}
.ChatBotList li.active, .ChatBotList li.active:hover, .ChatBotList li.active:focus {
    color: #f8f9fa;
    text-decoration: none;
    background-color: #273846cc;
}
.ChatBotList li .icon {
    width: 20%;
    box-sizing: content-box;
    display: inline-block;

}
.ChatBotList li .icon i {
    padding: 10px;
}
.ChatBotList li a {
    width: 80%;
    height: 100%;

    display: inline-block;
    text-decoration: none;
    padding: 10px;
    color: inherit;
}



.ChatBotSession .ChatBotMessage {
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    color: #273846cc;
    max-width: 70%;
    word-wrap: break-word;

}

.ChatBotSession .ChatBotMessage.ChatBotMessageBot {
    color: #273846cc;
    border: 1px solid rgba(39, 56, 70, 0.041);
}

.ChatBotSession .ChatBotMessage.ChatBotMessageUser {
    background-color: #f8f9fa;
    color: #273846cc;
    margin-left: auto;
}

.ChatBotSessionBody {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: hidden;
    position: relative;
}

.ChatBotSessionMessages {
    max-height: 80vh;
    padding-bottom: 20vh;
    overflow-y: scroll;
}
.ChatBotSessionMessages code {
    display: block;
}
.ChatBotSessionInput {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
    background-color: #FFFFFF;
    border-radius: 5px;
    padding: 10px;
    border-top: 1px solid rgba(202, 202, 202, 0.21);
    margin-left: auto;
}

.ChatBotSessionInput textarea {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 5px;

}

.ChatBotSessionInput textarea:focus {
    outline: none;
}

.ChatBotSessionInput button {
    border: none;
    background-color: #52606b;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    float: right;
}
.ChatBotSessionMessageActions {
    display: flex;
    justify-content: end;
    margin: 10px;
    gap: 5px;
}
.fa-thumbs-up.active {
    color: #429faa
}
.fa-thumbs-down.active {
    color: #FF4F4F
}