.NewAgreementPrice {
    min-width: 1000px !important;
}
.NewAgreementPrice .modal-header .modal-title button {
    position: absolute;
    right: 2%;
    color: white;
    font-size: 25px;
    top: 5px;
    border: none;
}
.NewAgreementPrice .modal-body {
    padding: 1.5rem;
    /* max-height: 900px; */
    overflow-y: scroll;
}
.NewAgreementPrice .modal-content {
    padding: 0;
    min-width: 999px !important;
    min-height: 700px;
}
.NewAgreementPrice .modal-footer {
    display: block;
    border: none;
}
.NewAgreementPrice .modal-header {
    justify-content: center;
}
.NewAgreementPrice .modal-header .modal-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: uppercase;
}
.NewAgreementPrice .reservation-modal-btn-primary {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewAgreementPrice .reservation-modal-btn-secondary {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
    cursor: pointer;
}
.NewAgreementPrice .price-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;
}

.NewAgreementPrice .promo-card {
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #0082a5, #465579);
    border-color: transparent;
    box-shadow: -10px 10px 6px 0px rgb(152 173 214 / 36%);
    color: white;
    padding: 0.8em 1em;
    border-radius: 10px;
    margin: 0px 0px 15px 35px;

}

.NewAgreementPrice .price-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewAgreementPrice .promo-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    padding: 5px 20px;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}

.NewAgreementPrice .price-card .price {
    background-color: #E5E5E5;
    color: #000000;
}
.NewAgreementPrice .price-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}
.NewAgreementPrice .promo-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.NewAgreementPrice .price-card p, .NewAgreementPrice .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewAgreementPrice .promo-card p, .NewAgreementPrice .card-title {
    font-size: 1.3em;
    margin-bottom: 0;
    line-height: 2em;
}
.NewAgreementPrice .price-card .price-card-count {
    background: #c2ff71;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    float: right;
    top: -2px;
    right: -7px;
    position: relative;
}
.NewAgreementPrice .card {
    border-color: transparent
}

.NewAgreementPrice .plan-detail-card {
    /* background: #475579; */
    background: linear-gradient(145deg, rgba(31, 27, 27, 1) 0%, #475579, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewAgreementPrice .plan-detail-card.active {
    background: #475579;
}

.NewAgreementPrice .promo-detail-card {
    /* background: #0082a5; */
    background: linear-gradient(145deg, rgba(30, 27, 27, 1) 0%, #0082a5, #465579);
    color: white;
    padding: 0.75rem 1rem;
    margin-top: 20px;
    border-radius: 10px;
}
.NewAgreementPrice .promo-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}
.NewAgreementPrice .plan-detail-card .card-header {
    background: transparent !important;
    margin-left: 0;
    padding-left: 0;
}

.NewAgreementPrice .promo-detail-card.active {
    background: #0082a5;
}

.NewAgreementPrice .promo-detail-card label,
.NewAgreementPrice .plan-detail-card label {
    font-size: 1rem;
    font-weight: 500 !important;
    padding-bottom: 7px;
}
.NewAgreementPrice .price-card label,
.NewAgreementPrice .promo-detail-card label,
.NewAgreementPrice .plan-detail-card label {
    color: white;
    font-weight: 100;
    opacity: 1;
}
.NewAgreementPrice .plan-detail-card .plan-tiers label {
    display: block;
}
.NewAgreementPrice .plan-detail-card .plan-tiers p {
    font-size: 14px;
    width: 25%;
    display: inline-block;
    padding: 0px 5px;
    margin-right: 10px;
}

.NewAgreementPrice .plan-detail-card button {
    background-color: white;
    border-color: white;
    color: #3e4c6d;
    width: 55%;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
.NewAgreementPrice .plan-detail-card .card-body {
    padding: 0;
}
.NewAgreementPrice .promo-detail-card .card-body {
    padding: 0;
}
.NewAgreementPrice .plan-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewAgreementPrice .promo-detail-card i.remove-plan span {
    font-family: 'Rubik';
}
.NewAgreementPrice .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 20px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.NewAgreementPrice .review-plan-details {
    border: 2px solid #e4c83c87;
    border-radius: 5px;
    margin: 0px 0px 15px 0px;
    padding-top: 15px;
    width: 100%;
}
.NewAgreementPrice .plan-detail-card i.remove-plan {
    position: absolute;
    bottom: 0px;
    right: 25px;
    color: white;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.NewAgreementPrice .card-header {
    font-weight: 600 !important;
    font-size: 15px !important;
    line-height: 2rem !important;
}
.NewAgreementPrice .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.NewAgreementPrice .card-text b {
    display: block;
    line-height: 10px;
    padding-top: 15px;
}
.NewAgreementPrice i.fa-check-square {
    color: #6dec6d;
    font-size: 18px;
    padding-top: 5px;
    padding-right: 5px;
}
.NewAgreementPrice .card-text b span {
    font-size: 24px;
}
/* .swiper-button-prev {
    left: -2px !important;
    color: black !important;
}
.swiper-button-next {
    right: -2px !important;
    color: black !important;
} */
.NewAgreementPrice i.switch {
    font-size: 25px;
    border: none;
}
.NewAgreementPrice .form-control {
    font-size: 14px;
}
.NewAgreementPrice a {
    cursor: pointer;
    text-decoration: underline;
}

.NewAgreementPrice .drivers-list {
    border: none;
    border-radius: 10px;
    padding: 10px;
    box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    /* min-width: 250px; */
}

.NewAgreementPrice label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}
.NewAgreementPrice .car-name-years .car-name {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.NewAgreementPrice .selection-container {
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    text-align: center;
}

.NewAgreementPrice .selection-container .disable-car, .NewAgreementPrice .selection-container .disable-car:hover {
    color: gray;
    cursor: text;
}

.NewAgreementPrice .selected {
    color: #ffffff;
    background-color: #3e4c6d;
    border-radius: 10px;
}

.NewAgreementPrice .user-icon .fa {
    padding: 10px 13px;
    border-radius: 50%;
    position: relative;
    color: #3e4c6d;
    background-color: #ffffff;
}

.NewAgreementPrice .edit-item {
    position: relative;
}
.NewAgreementPrice .edit-item .edit-icon {
    position: absolute;
    right: 10px;
    top: 10px;
}
.NewAgreementPrice .edit-item .edit-icon {
    position: relative;
    position: absolute;
    right: 10px;
    top: 10px;
}
.selection-box {
    min-height: 100px;
    max-height: 150px;
    width: 533px;
    border-radius: 10px;
    margin: 15px;
    background-color: #FFFFFF;
    justify-content: space-evenly;
    padding: 2rem;
    cursor: pointer;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.selection-box-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #949494;
}
.selection-box-icon {
    line-height: 41.75px;
    font-weight: 400;
    font-size: 20px;
}
.close-selection-box-icon {
    position: absolute;
    top: -35px;
    right: 5px;
    font-size: 20px;
}
.grey-link {
    color: #ffffff
}