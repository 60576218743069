.repAssignmentModal .multiSelectButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.repAssignmentModal .multiSelectButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}
.repAssignmentModal .btn-outline-primary, .repAssignmentModal .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled):active, .repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled).active, .repAssignmentModal .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.repAssignmentModal .btn-outline-primary:hover,.repAssignmentModal .btn-outline-primary:active,.repAssignmentModal .btn-outline-primary:focus,
.repAssignmentModal .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.submit-button{
    border-radius: 8px;
    background-color: #1B4D7E;
}