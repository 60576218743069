.cars-data-table-container {
	/* max-height: 67vh;
	overflow-y: auto; */
}
.data-table-filters-container .bulk-action-wrapper {
	padding: 15px 0px 0px 5px;
	font-weight: 500;
}
.data-table-filters-container .bulk-action-wrapper a:hover i,
.data-table-filters-container .bulk-action-wrapper a:hover {
	background-color: #2acd72;
	color: white !important;
}
.data-table-filters-container .bulk-action-wrapper a {
	cursor: pointer;
	font-size: 13px;
	text-transform: uppercase;
	border: 1px solid #2acd72;
	padding: 5px 10px;
	border-radius: 7px;
}
.data-table-filters-container .bulk-action-wrapper i {
	color: #79ff4859 !important;
}
.cars-data-table-container td {
	font-size: 13px !important;
}
.cars-data-table-container::-webkit-scrollbar,
.data-table-filters-container::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
.cars-data-table-container::-webkit-scrollbar-track,
.data-table-filters-container::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.cars-data-table-container::-webkit-scrollbar-thumb,
.data-table-filters-container::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
}
.cars-management-container .progress,
.cars-data-table-container .progress {
	height: 0.5vh;
}
.cars-data-table-container .car-name {
	max-width: 175px;
	margin-right: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.cars-data-table-container .notes .note-text {
	max-width: 125px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
}
.ReservedCarsContainer.CarListContainer .row,
.FleetManagementContainer.CarListContainer .row {
	margin-bottom: 0;
}

.CarListContainer .table th, .table td {
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.CarListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.CarListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.ReservedCarsContainer.CarListContainer .card,
.FleetManagementContainer.CarListContainer .card {
	margin-bottom: .5rem;
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.date-range-group .input-group-text i {
	color: white !important;
}
.date-range-group .input-group-text {
	background-color: #2acd72 !important;
}
.CarListContainer .date-range-group input {
	font-weight: 500;
	color: #303e67 !important;
	font-size: 13px;
}

.available-car-wrap.nav-pills .nav-item .btn.btn-primary {
	padding: 9px 25px;
	font-size: 14px;
	/* font-weight: 500; */
	color: #303e67;
	background-color: #c6ccde;
	border: none;
	border-radius: 8px;
	margin-top: -10px;
}
.available-car-wrap.nav-pills .nav-item .btn.btn-primary:focus {
	box-shadow: none;
}
.available-car-wrap.nav-pills .nav-item .btn.btn-primary.active {
	background: #fff;
}
.available-car-wrap.nav-pills .nav-item {
	margin: 0.5rem 25px;
	margin-left: 0;
}
.AvailableCarsContainer .card {
	margin-bottom: 0.5rem;
}
.reset-date-range {
	position: absolute;
	right: -5px;
	top: -5px;
	font-size: 22px;
}
/* .cars-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
} */
.reservation-driver-icon,
.return-driver-icon,
.cars-wrapper .car-info {
	text-align: center;
	float: left;
	padding-right: 10px;
	margin-top: 6px;
}
.view-prices .all-prices {
	display: inline-block;
	padding-right: 3px;
}
.cars-wrapper .car-actions {
	float: right;
}
.cars-wrapper .car-actions ul {
	padding-top: 5px;
	padding-right: 1.5rem;
	margin: 0;
	/* padding: 0 40px 0 0; */
}
.old-view {
	position: absolute;
	bottom: 10px;
	right: 125px;
	border-right: 1px solid #DC3546;
	padding-right: 10px;
	color: #DC3546 !important;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: underline !important;
	cursor: pointer;
}
.more-filter-button {
	position: absolute;
	bottom: 10px;
	right: 25px;
	color: #303E67 !important;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	text-decoration: underline !important;
	cursor: pointer;
}

.reservation-driver .reservation-pickup {
	color: #303E67;
}
.reservation-driver,
.return-driver {
	float: left;
	text-align: left;
	padding-left: 10px;
	border-left: 1px solid #e1e1e1;
}
.return-driver .return-reason {
	width: 75px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	vertical-align: middle;
}

.reservation-driver .reservation-driver-name,
.return-driver .return-driver-name {
	max-width: 175px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
.cars-wrapper .car-id-detail {
	float: left;
	text-align: center;
	margin-top: 8px;
}
.view-prices .car-pricing-default {
	text-align: center;
	display: inline-block;
	padding-left: 10px;
	vertical-align: middle;
	border-left: 1px solid #e1e1e1;
}
.cars-wrapper .car-name-years {
	float: left;
	padding-left: 20px;
}
.ReservationsListContainer {
	font-family: "Poppins" !important;
}
.ReservationsListContainer .bos-badge-blue {
	font-size: 10px !important;
}
.cars-wrapper .car-name-years .car-name {
	font-weight: 500;
}
.car-location.not-bold {
	font-weight: 400;
}
.car-location, .car-tags {
	font-weight: 500;
	display: flex;
	max-width: 250px;
}
.view-prices a {
	font-weight: 500;
	font-size: 14px;
	color: #232323;
	text-decoration: none;
	padding-left: 5px;
	display: inline-block;
	cursor: pointer;
}
.attachment {
	padding-top: 17px;
	padding-left: 10px;
}
.attachment a {
	cursor: pointer;
}
.return-date {
	margin-top: 23px;
}
.reason {
	margin-top: 10px;
}
.drivers {
	margin-top: 15px;
	width: 160px;
	position: relative;
}
.drivers .explation-mark i {
	position: absolute;
	top: 12px;
	right: -18px;
}
.edit-location-icon {
	font-size: 20px;
	display: inline-block;
	border-right: 1px solid #e1e1e1;
	margin-right: 10px;
	padding-right: 10px;
}
.actions {
	padding-left: 0;
	padding-left: 10px;
}
.actions li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}
.actions li {
	display: inline-block;
	margin-right: 10px;
}
.actions li a {
	cursor: pointer;
}
.modalcarinfo .modal-content {
	padding: 0px;
}
.search-button {
	position: absolute;
	right: 0;
	top: 0;
	background: #303e67;
	border-color: #303e67;
	margin-bottom: 30px;
}
.toggle-switch {
	position: relative;
	width: 60px;
	padding: 0px 9px;
	display: inline-block;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-align: left;
}
.toggle-switch-checkbox {
	display: none;
}
.toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #bbb;
	border-radius: 20px;
	margin: 0;
}
.toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 24px;
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	color: white;
	font-weight: 500;
	box-sizing: border-box;
}
.toggle-switch-inner:before {
	content: "";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: #DC3546;
	color: #fff;
}
.toggle-switch-disabled {
	background-color: #ddd;
	cursor: not-allowed;
}
.toggle-switch-disabled:before {
	background-color: #ddd;
	cursor: not-allowed;
}
.toggle-switch-inner:after {
	content: "";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #2acd72;
	color: #fff;
	text-align: right;
}
.toggle-switch-switch {
	display: block;
	width: 18px;
	margin: 3px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 25px;
	border: 0 solid #bbb;
	border-radius: 20px;
	transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 10px;
}
.nav.nav-pills .nav-item {
	margin-right: 20px;
	border-radius: 8px;
}
.nav.nav-pills .nav-item .nav-item {
	border-radius: 8px 8px 0px 0px;
	padding: 20px 30px;
	background-color: #c6ccde;
	color: #303e67;
}
.nav.nav-pills .nav-item .nav-item span {
	margin-top: 6px;
	display: inline-block;
}
.nav.nav-pills .nav-item .nav-item.active {
	background-color: #fff;
	color: #303e67;
}
@media (min-width: 576px) {
	.modalcarinfo {
		max-width: 1080px !important;
	}
	.modalcarinfo .modal-body {
		padding: 40px 50px;
	}
}
.modalcarinfo label {
	display: block;
	width: 100%;
	font-weight: 500;
}
.modalcarinfo .modal-body .input-group {
	margin-bottom: 30px;
}
.modalcarinfo .modal-body .input-group input.readonly {
	background-color: #e9ecef;
}
.modalcarinfo .modal-body .input-group input {
	border-radius: 4px;
	padding: 10px 20px;
	height: auto;
}
/* .modalcarinfo .modal-body .input-group .input-group-prepend .btn.btn-secondary {
	 background-color: #e9ecef;
	 color: #ced4da;
	 border: none;
	 border-radius: 8px;
} */
.modalcarinfo .modal-body .driver-wrapper.green span {
	color: #fff;
}
.modalcarinfo .modal-body .driver-wrapper.green strong {
	color: #fff;
}
.modalcarinfo .modal-body .driver-wrapper.green {
	background-color: #198754;
}
.modalcarinfo .modal-body .driver-wrapper {
	padding: 25px 10px;
	border: 1px solid #ced4da;
	border-radius: 5px;
	margin-bottom: 20px;
	/* float: left; */
	/* width: 100%; */
}
.modalcarinfo .modal-body .driver-wrapper .driver-info {
	float: left;
	padding-left: 20px;
}
.modalcarinfo .modal-body .driver-wrapper .driver-info span {
	display: inline-block;
}
/* .modalcarinfo .modal-body .driver-wrapper .driver-info-license {
	 float: left;
	 width: 50%;
	 padding-bottom: 14px;
} */
.modalcarinfo .modal-body .driver-wrapper .driver-info-license strong {
	display: block;
	margin-top: 15px;
}
.modalcarinfo .modal-body h4 {
	font-size: 16px;
	margin-bottom: 17px;
}
.modalcarinfo .modal-header {
	background-color: #303e67;
	color: #fff;
	padding-bottom: 20px;
}
.modalcarinfo .modal-header .close {
	color: #fff;
	opacity: 1;
	margin-right: 10px;
	/* margin-top: 0px; */
}
.modalcarinfo .modal-footer {
	border: none;
	padding: 20px;
}
/* .modalcarinfo .modal-footer .btn.btn-secondary {
	 background-color: #303e67;
	 border-color: #303e67;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
} */
/* .modalcarinfo .modal-footer .btn.btn-primary {
	 background-color: #198754;
	 border-color: #198754;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
}
 .modalcarinfo .modal-footer .btn.btn-danger {
	 background-color: #DC3546;
	 border-color: #DC3546;
	 color: #fff;
	 padding: 13px 22px;
	 font-weight: 500;
	 font-size: 15px;
	 margin-right: 20px;
} */
.modalcarinfo .carissue-open {
	background-color: transparent;
	color: #DC3546;
	display: block;
	width: 100%;
	text-align: left;
	padding: 15px 30px;
	text-transform: uppercase;
	border: 1px solid#DC3546;
	border-radius: 6px;
	font-weight: 500;
}
.modalcarinfo .carissue-open:hover, .modalcarinfo .carissue-open:focus, .modalcarinfo .carissue-open:active {
	box-shadow: none;
	background-color: #dc354648 !important;
	border-color: #DC3546 !important;
}
.modalcarinfo .carissue-open span {
	float: right;
	font-weight: 400;
}
.modalcarinfo .carissue-open span strong {
	font-weight: 700;
}
.modalcarinfo .carissue-closed {
	background-color: transparent;
	color: #198754;
	display: block;
	width: 100%;
	text-align: left;
	padding: 15px 30px;
	text-transform: uppercase;
	border: 1px solid #198754;
	border-radius: 6px;
}
.modalcarinfo .carissue-closed:hover, .modalcarinfo .carissue-closed:focus, .modalcarinfo .carissue-closed:active {
	box-shadow: none;
	background-color: #1987543c !important;
	border-color: #198754 !important;
}
.modalcarinfo .carissue-closed span {
	float: right;
	font-weight: 400;
}
.modalcarinfo .carissue-closed span strong {
	font-weight: 700;
}
.search-filter {
	position: relative;
}
.search-filter .search-icon.btn {
	background-color: #303e67;
}
.search-filter .search-icon {
	position: absolute;
	right: 0;
	top: 0;
}
.cargadgets-button {
	background-color: transparent;
	padding: 0;
	margin-top: -28px;
	border: none;
}
.cargadgets-button:hover, .cargadgets-button:focus, .cargadgets-button:active {
	background-color: transparent;
	outline: none;
	box-shadow: none;
}
.popover.cargadgets-popover {
	width: 500px;
}
.cargadgets-popover.popover {
	max-width: 350px !important;
}
.cargadgets-popover.popover .popover-header {
	margin-bottom: 15px;
	font-size: 17px;
	text-align: center;
}
.cargadgets-popover.popover .popover-body {
	padding: 0 20px;
	text-align: center;
}
.pricing-details {
	background-color: #e9edf0;
}
.pricing-details .pricing-heading {
	background-color: #313e68;
	border-radius: 7px 7px 0 0;
	padding: 20px 0;
	color: #fff;
	text-align: center;
}
.pricing-details .pricing-price {
	display: block;
	padding: 30px 0;
	text-align: center;
}
.pricing-details span:not(.pricing-price) {
	padding: 0 0 20px 0;
	font-size: 14px;
	text-align: center;
	display: block;
}
.car-location .location-button {
	display: inline-block;
}
.fa-wrench.fa-2x {
	font-size: 1.5em !important;
}
.btn-outline-primary {
	border-color: #52606b;
	background-color: transparent;
	color: #52606b
}
.btn-outline-primary:hover, .btn-outline-primary:focus {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;

}

/* .modalcarinfo .btn.btn-secondary.carinfo-label {
	 background-color: #313e68;
	 display: block;
	 width: 100%;
	 border-radius: 5px;
	 padding: 10px 0px;
	 margin-top: 20px;
} */
/* .return-behaviour {
	 width: 100%;
	 display: block;
}
 .return-behaviour-popover .popover {
	 width: 800px;
	 max-width: 800px !important;
}
 .return-behaviour-popover .popover .cars-wrapper {
	 padding: 10px 0;
	 float: left;
}
 .return-behaviour-popover .popover .cars-wrapper .car-info {
	 float: left;
	 padding-left: 10px;
	 padding-right: 22px;
	 margin-top: 6px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail {
	 float: left;
	 text-align: center;
	 padding-right: 20px;
	 border-right: 1px solid #e1e1e1;
	 margin-top: 10px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail span {
	 font-weight: 500;
}
 .return-behaviour-popover .popover .cars-wrapper .car-id-detail span img {
	 margin-right: 10px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-name-years {
	 float: left;
	 padding-left: 20px;
}
 .return-behaviour-popover .popover .cars-wrapper .car-name-years .car-name {
	 font-weight: 500;
} */
/* .rbc-calendar {
	 margin-bottom: 80px;
}
 .rbc-calendar .rbc-time-content {
	 display: none;
} */