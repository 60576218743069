.subscriptionDetail label {
    opacity: .85;
    letter-spacing: .75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000;
}

.subscriptionDetail .modal-header .modal-title button {
    position: absolute;
    right: 2%;
    color: white;
    font-size: 25px;
    top: 5px;
    border: none;
}
.subscriptionDetail .modal-header {
    justify-content: center;
}
.subscriptionDetail .modal-header .modal-title {
    font-weight: 400;
    font-size: 2em;
    text-transform: uppercase;
}