.table-preferences-popover .popover-header {
    min-width: 450px;
}

.table-preferences-popover .popover-body {
    padding: 20px;
    color: #333;
}
.table-preferences-popover label {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
}