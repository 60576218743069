.permission-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    box-shadow: 10px 5px 20px 2px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 50px auto;
    max-width: 500px;
    text-align: center;
    height: 300px;
}

.permission-box-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.2s ease;
    text-transform: uppercase;
}

.permission-box-button:hover {
    background-color: #007bff;
    ;
}

.permission-box-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: red;
}

.permission-box-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.permission-box-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}