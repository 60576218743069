.search {
    border: none;
    border-radius: 10px;
    padding: 10px;
    background-color: #ffffff;
    box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -1px 4px 10px -1px rgba(0, 0, 0, 0.4);
}

.option-list {
    max-height: 150px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.option-list::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.option-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 0.3);
}

.option-list::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.option-list .option {
    font-weight: 500;
    font-size: 15px;
    font-family: 'Poppins';
    border-bottom: 1px solid;
    text-align: left;
    padding:  10px;
    text-transform: uppercase;
}

.option-list .option:hover {
    cursor: pointer;
    background-color: #303e67;
    color: white;
}

.option-list .option .label {
    font-size: .75em;
}

.option-list-wrapper-text {
    margin-left: 10px;
}
.option-list-wrapper-text p {
    font-size: 15px;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 5px !important;
    margin: 0px !important;
}
.option-list-wrapper-text small {
    padding-left: 5px !important;
}

.search .snippet {
    position: "absolute";
    right: 50;
    top: 20;
}

.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #9880ff;
    color: #9880ff;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.NewReservationModal .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
    0% {
        box-shadow: 9984px 0 0 -5px;
    }
    30% {
        box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
        box-shadow: 9999px 0 0 -5px;
    }
    30% {
        box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
        box-shadow: 10014px 0 0 -5px;
    }
    30% {
        box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
        box-shadow: 10014px 0 0 -5px;
    }
}