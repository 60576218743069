/* .pricing-list-container .row{
    margin-bottom: 0rem;
} */

.pricing-list-container .card {
    margin-bottom: .5rem;
    box-shadow: 3px 3px 9px 2px hsl(0deg 0% 79% / 21%);
    border: none;
    border-radius: 8px;
}

.prices-table-wrapper {
    /* max-height: 67vh;
	overflow-y: auto; */
}

.prices-table-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.prices-table-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.prices-table-wrapper::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.prices-table-wrapper .progress {
    height: 0.5vh;
}

.prices-table-wrapper table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
    color: rgba(39, 56, 70, .8);
}
.prices-table-wrapper table th {
    border: none;
    text-transform: uppercase;
    color: rgba(42, 53, 84, .52);
    font-size: .8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: .005em;
}

.prices-table-wrapper table td {
    color: #525f7f;
}

.prices-table-wrapper .fa {
    color: #303E67
}






.edit-pricing-modal.modal-dialog {
    min-width: 520px !important;
}
.edit-pricing-modal .modal-content {
    background-color: white !important;
    color: #525f6f !important;
}
.edit-pricing-modal .modal-content .modal-body {
    max-height: 75vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
.edit-pricing-modal .modal-content label {
    color: #525f6f !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    font-size: 80% !important;
    font-weight: 700 !important;
}
.edit-pricing-modal .modal-content p {
    margin-bottom: 0.25rem !important;

}
.edit-pricing-modal .modal-content .modal-header {
    justify-content: center !important;
}

.edit-pricing-modal .modal-content .modal-header .modal-title {
    font-weight: 400 !important;
    font-size: 1.75em !important;
    text-transform: uppercase !important;
    color: #303e67 !important;
}
.edit-pricing-modal-secondary-btn {
    background-color: white !important;
    border-radius: 7px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    color: black !important;
    cursor: pointer !important;
}

.edit-pricing-modal .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.edit-pricing-modal .modal-content .toggle-section-heading {
    display: block;
    color: white !important;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}

.edit-pricing-modal .modal-content .toggle-section-heading.active {
    /* background-color: #1A1C1E; */
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}