.listing-filters {
    border: none;
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    min-width: 250px;


}
.listing-filters .popover-header a {
    cursor: pointer;
}
.listing-filters .popover-header a:hover {
    text-decoration: underline !important;
}
.listing-filters .popover-body {
    padding: 0;
    overflow: visible !important;

}
.listing-filters .filter__wrapper {
    min-width: 100px;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border-bottom: 1px solid #C0C8D2;
    /* padding: .5rem; */
}
.listing-filters .filter__wrapper .filter-title {
    color: #414552;
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 0.5rem;
}
.popover {
    z-index: 10050 !important;
}

.popover-arrow {
    pointer-events: none !important;
}