.bos-listing-container .table a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.bos-listing-container .table a:hover {
    color: #0056b3;
    text-decoration: underline;
}
.bos-create-tenant-modal {
    max-width: 50%;
}
.bos-create-tenant-modal.modal-dialog {
    max-width: 800px !important;
}
.bos-create-tenant-modal .modal-content {
    background-color: white;
    color: #525f6f;
}
.members-options-wrapper {
    max-height: 20vh;
    overflow-y: scroll;
}
.members-options-wrapper::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.members-options-wrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.members-options-wrapper::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}

.new-tenant-modal-primary-btn {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.new-tenant-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.new-tenant-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

#box-object-icon {
    background-color: white;
    border: 7px solid #f2f3f6;
    padding: 32px 5px;
    border-radius: 50%;
    position: relative;
    bottom: -15px;
}