@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.document-box {
    padding: 1.25rem 0.25rem;
    overflow: hidden;
}
.document-box .document-main {
    background-color: #D6D6D6;
    box-shadow: 3px 4px 12px 3px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 20px 7px 10px 7px;
    margin: 0rem;
}
.document-box .document-main:hover {
    cursor: pointer;
}

.document-box .document-main .arrow-up-down-button {
    background-color: white;
    padding: 8px 11px;
    border-radius: 25px;
}
.document-box .document-main.active {
    background-color: #2DAFD3;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.document-box .document-popup a {
    cursor: pointer;
    color: #007bff;
}
.document-box .document-popup a:hover {
    color: #007bff;
    text-decoration: underline;
}
.document-box .document-main label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.document-box .document-main .row {
    margin: 0px;
}
.document-box .document-main .row p:nth-child(2) {
    margin-left: auto;
}
.document-box .document-main .fa-file-pdf-o, .fa-picture-o {
    cursor: default;
    margin-left: auto;
}
.document-box .document-main .selected-document {
    color: #fff
}
.document-box .document-main .not-selected-document {
    color: #000;
}
.document-box .document-popup {
    margin: 0;
    background-color: #e0e0e0;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid #E7E9EC;
    padding: 12px;
}

.document-box .document-popup .document-popup-button-container button {
    background: none !important;
    border: none;
    padding-right: 1.25rem;
    padding-left: 0rem;
    font-family: arial, sans-serif;
    color: #069;
    cursor: pointer;
}
.document-box .document-popup .document-popup-button-container button:hover {
    text-decoration: underline;
}
.document-box .document-popup .document-button .remove-open {
    border-radius: 8px;
    height: 38px;
    text-align: center;
    font-size: 11px;
    font-weight: 700;
    margin-right: 0px;
}
.equipments-tab .toggle-switch-inner:after, .toggle-switch-inner:after {
    content: "";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #2acd72;
    color: #fff;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}

.documents-tab .upload-document, .equipments-tab .install-equipment {
    border-radius: 12px;
    background-color: #1B4D7E;
    width: 180px;
    height: 45px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
}