.returning-cars-table.table th, .returning-cars-table.table td {
    padding: 0.75rem 0.25rem;
}

.returning-cars-table .cars-wrapper .car-id-detail {
    border: none;
    padding-left: 0;
}
.returning-cars-table .branch-badge {
    text-transform: uppercase;
    padding: 5px;
    background-color: #0b80f9;
    margin-left: 0;
}