.car-registration-container h4 {
	font-weight: 500;
	font-size: 1.1em;
	margin: 0;
}
.car-registration-container .car-registration-filters-container .form-group {
	margin-bottom: 0;
}

.car-registration-container .car-registration-filters-container .searchterm-wrapper {
	position: relative;
}

.car-registration-container .car-registration-filters-container .searchterm-wrapper .btn {
	position: absolute;
	right: 2%;
	top: 0;
	background-color: #303e67;
	z-index: 111;
}

.car-registration-container .car-registration-table-container {
	max-height: 65vh;
	overflow-y: auto;
}
.car-registration-container .car-registration-table-container thead th {
	border: none !important;
}
.car-registration-container .car-registration-table-container tr {
	border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
}
.car-registration-container .car-registration-table-container th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, .52);
	font-size: .8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: .005em;
}
.car-registration-container .car-registration-table-container .car-details-wrapper,
.car-registration-container .car-registration-table-container .registration-details-wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.car-registration-container .car-registration-table-container .car-details-wrapper .left-box,
.car-registration-container .car-registration-table-container .registration-details-wrapper .left-box {
	padding-right: 1rem;
	text-align: center;
	border-right: 1px solid #e1e1e1;
}
.car-registration-container .car-registration-table-container .car-details-wrapper .right-box,
.car-registration-container .car-registration-table-container .registration-details-wrapper .right-box {
	padding-left: 1rem;
}
.car-registration-container .car-registration-table-container .right-actions {
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-self: flex-end;
}
.car-registration-container .car-registration-table-container .right-actions ul {
	padding-right: 3rem;
	padding-left: 2px;
}
.car-registration-container .car-registration-table-container .right-actions ul li:not(:first-child) {
	border-left: 1px solid #e1e1e1;
	padding-left: 10px;
}
.car-registration-container .car-registration-table-container .right-actions ul li {
	display: inline-block;
	margin-right: 10px;
}
.car-registration-container .car-registration-table-container .right-actions ul li a {
	cursor: pointer;
}
.car-registration-container .car-registration-table-container .car-details-section {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.car-registration-container .car-registration-table-container .car-details-section .left-box__ {
	padding-right: 1rem;
	text-align: center;
}

.bos-registration-modal .modal-content small,
.bos-registration-modal .modal-content label {
	color: rgb(73, 73, 73);
	text-transform: uppercase;
	letter-spacing: 0.75px;
}

.registration-activity-modal {
	min-width: 850px;
	font-size: 13px;
}
.registration-activity-modal .modal-header {
	align-self: center;
}
.registration-activity-modal .modal-title {
	text-transform: uppercase;
}
.registration-activity-modal table thead th {
	border: none;
	text-transform: uppercase;
	font-size: 11px;
	opacity: 0.6;
	padding-bottom: 0;
}
.registration-activity-modal table thead,
.registration-activity-modal table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.606);
}
.registration-activity-modal table tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary {
	padding: 9px 25px;
	font-size: 16px;
	color: #303e67;
	background-color: #c6ccde;
	border: none;
	border-radius: 8px;
	margin-top: -10px;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary:focus {
	box-shadow: none;
}
.car-registration-container .registration-tabs.nav-pills .nav-item .btn.btn-primary.active {
	background: #fff;
}
.car-registration-container .registration-tabs.nav-pills .nav-item {
	margin: 0.5rem 1.75rem 2rem 1.75rem;
	margin-left: 0;
}