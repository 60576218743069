.table-container {
  overflow-y: auto !important; 
  max-height: 65vh !important;
  display: flex !important;
  flex-direction: column !important;
} 

.table-container table {
  width: 100% !important;
}
.table-container thead th {
  position: sticky !important;
  background-color: #f2f2f2 !important; 
  top: -1px !important;
  cursor: pointer !important;
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}
.table-container thead tr th:first-child{
  border-top-left-radius: 10px;
}
.table-container thead tr th:last-child{
  border-top-right-radius: 10px;
}
.table-container tr:hover {
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3) !important;
}
::-webkit-scrollbar-thumb {
  background-color: #303e67 !important;
  outline: 1px solid #303e67 !important;
}

::-webkit-scrollbar {
  margin-left: 10px !important;
}

::-webkit-scrollbar {
  margin-left: 10px !important;
} 





