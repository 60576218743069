.notes_history {
    box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: -1px 4px 20px -1px rgba(0, 0, 0, 0.8);
}

.notes_history .notes_container {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.notes_history .notes_container p {
    padding: 7px 20px;
    margin: 0px;
    border-bottom: 2px solid #028b8b;
}

.notes_history .popover-body .show_more {
    background-color: #303e67;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.notes_history .popover-body .close_history {
    background-color: white;
    border-radius: 7px;
    font-size: 10px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}

.notes_history .popover-body .close_history:hover {
    background-color: gray;
    color: white;
}