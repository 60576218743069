.submitToInsuranceForm .switchButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.submitToInsuranceForm .switchButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}

.submitToInsuranceForm .multiSelectButton{
    color: #52606b;
	border-color: #52606b;
    background-color: transparent;
    margin:10px
}
.submitToInsuranceForm .multiSelectButton.active{
    color: #fff;
	border-color: none;
    background-color: #52606b;
    margin:10px
}
.submitToInsuranceForm .btn-outline-primary, .submitToInsuranceForm .btn-outline-primary{
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled):active, .submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled).active, .submitToInsuranceForm .show > .btn-outline-primary.dropdown-toggle{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}

.submitToInsuranceForm .btn-outline-primary:hover,.submitToInsuranceForm .btn-outline-primary:active,.submitToInsuranceForm .btn-outline-primary:focus,
.submitToInsuranceForm .btn-outline-primary:not(:disabled):not(.disabled):active
{
	color: #fff;
    background-color: #52606b;
    border-color: #52606b;
}