@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.ReservationsListContainer .row {
	margin: 5px 0px 10px;
	font-size: 0.93em;
}
.ReservationsListContainer {
	color: #273846cc;
	font-family: 'Rubik', 'San serif';

}
.ReservationsListContainer .btn-outline-primary, .ReservationsListContainer .btn-outline-primary {
	color: #52606b;
	border-color: #52606b;
	background-color: transparent;
}
.ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active, .ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled).active, .ReservationsListContainer .show > .btn-outline-primary.dropdown-toggle {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.ReservationsListContainer .btn-outline-primary:hover, .ReservationsListContainer .btn-outline-primary:active, .ReservationsListContainer .btn-outline-primary:focus,
.ReservationsListContainer .btn-outline-primary:not(:disabled):not(.disabled):active {
	color: #fff;
	background-color: #52606b;
	border-color: #52606b;
}

.ReservationsListContainer .nav-pills .nav-item {
	margin: 10px;
}
.popover {
	max-width: 500px;
}
.ReservationsListContainer .uppercase {
	text-transform: uppercase;
}
.ReservationsListContainer .capitalize {
	text-transform: lowercase;
}
.ReservationsListContainer .capitalize::first-letter {
	text-transform: capitalize;
}
.ReservationsListContainer .linkHoverEffect {
	position: relative;
}
.ReservationsListContainer .linkHoverEffect:hover:after {
	width: 100%;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.ReservationsListContainer .linkHoverEffect:after {
	position: absolute;
	content: "";
	width: 0%;
	margin-top: 4px;
	height: 2px;
	background-color: #52606b;
	left: 0;
	bottom: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
i {
	cursor: pointer;
}
.ReservationsListContainer .alert {
	text-align: center;
	border: none;
}
.ReservationsListContainer .alert-danger {
	background-color: #FF4F4F;
	color: #fff;

}
.ReservationsListContainer .alert-primary {
	background-color: #273846cc;
	color: #fff;
}
.ReservationsListContainer .card {
	box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
	border: none;
	border-radius: 8px;
}

.ReservationsListContainer .table th, .table td {
	vertical-align: middle;
	border: none;
	padding-bottom: 10px;
	padding-top: 10px;
}

.ReservationsListContainer .table tr {
	border-bottom: 1px solid rgba(202, 202, 202, 0.21);
	margin: 10px;
	color: #273846cc;
}
.ReservationsListContainer .table th {
	text-transform: uppercase;
	color: rgba(42, 53, 84, 0.52);
	font-size: 0.8em;
	font-weight: 500;
	vertical-align: middle;
	letter-spacing: 0.005em;
}

.shortText {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}

/* Style Input Range Slider */

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
}
input[type=range]:focus {
	outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-webkit-slider-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -8px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
	background: #273846cc;
}
input[type=range]::-moz-range-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: #273846cc;
	border-radius: 2px;
}
input[type=range]::-moz-range-thumb {
	border: 1px solid #273846cc;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]::-ms-track {
	width: 100%;
	height: 8.4px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	border-color: transparent;
	border-width: 16px 0;
	color: transparent;
}
input[type=range]::-ms-fill-lower {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #273846cc;
}
input[type=range]::-ms-fill-upper {
	background: #273846cc;
	border-radius: 8px;
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #0d0d0d;
}
input[type=range]::-ms-thumb {
	box-shadow: 1px 1px 1px #273846cc, 0px 0px 1px #000000;
	height: 25px;
	width: 25px;
	border-radius: 8px;
	background: #ffffff;
	cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
	background: #273846cc;
}
input[type=range]:focus::-ms-fill-upper {
	background: #273846cc;
}
.microDashboard card {
	border: none;
}
/* End of Input Range Slider style */