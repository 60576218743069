.card-component {
	font-family: "Roboto";

}
.card-component label {
	text-transform: uppercase;
	letter-spacing: 0.15px;
	word-wrap: break-word
}
.card-component-btn {
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
	border: 0;
}
.card-component .set-as-default {
	color: #0000EE !important;
	text-decoration: underline !important;
	text-transform: capitalize !important;
	cursor: pointer;
}
.card-component .toggle-section-heading i {
	float: right;
	margin-top: 0.25rem;
}
.card-component .toggle-section-heading {
	display: block;
	color: #ffffff;
	background-color: #2DAFD3;
	border-radius: 5px;
	padding: 0.7rem 1rem;
	font-size: 1em;
	margin-bottom: 1rem;
}
.card-component .toggle-section-heading.active {
	background-color: #2DAFD3;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	color: #ffffff;
}

.card-component .stipe-charge-history-wrapper {
	max-height: 500px;
}
.card-component .stipe-charge-history-row {
	background-color: #F0F0EB;
	padding-top: 0.5rem;
	margin: 0.5rem 0.15rem;
	border-bottom: 3px solid rgb(133, 133, 133);
}