.dtac-history-tooltip {
    opacity: 1 !important;
}
.dtac-history-tooltip .tooltip-inner {
    min-width: 175px;
    max-width: 300px;
    padding: 0.25rem 1rem 0.5rem 1rem;
    width: auto;
    text-align: left;
    background-color: #F2F2F2;
    color: black;
    border: 1px solid #d7dce2;
    max-height: 200px;
    overflow: auto;
}
.dtac-history-tooltip label {
    display: block;
    text-align: center;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bold;
}
.dtac-history-tooltip ul {
    padding-left: 1rem;
    padding-bottom: 0px;
}