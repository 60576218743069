.dtac-modal.modal-dialog {
	max-width: 600px !important;
}

.dtac-modal .modal-content {
	background-color: white;
	color: #525f6f;
}

.dtac-modal .modal-content .modal-header .modal-title {
	font-weight: 400 !important;
	font-size: 1.75em !important;
	text-transform: uppercase !important;
    text-align: left;
}
.dtac-modal-primary-btn {
	background-color: #303e67;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: white;
	cursor: pointer;
}
.dtac-modal-secondary-btn {
	background-color: white;
	border-radius: 7px;
	font-size: 13px;
	font-weight: 400;
	color: black;
	cursor: pointer;
}