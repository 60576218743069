@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.StyledToast{
    padding: 20px 15px;
    background-color: rgb(225 235 239 / 67%);
    border-radius: 8px;
}

.StyledToast .toastHeader{
    border-bottom: 1px solid hsla(0,0%,79.2%,.21);
    font-weight: 500;
    padding-bottom: 5px;
}