.NotificationsListModal {
    margin-right: 20px;
    top: 10vh;
}
.NotificationsListModal .modal-header {
    background-color: rgb(65, 80, 93);
    color: #ffffff;
}
.NotificationsListModal .modal-body {
    padding: 10px 0;
}
.notificationContent {
    margin-bottom: 10px;
}
.NotificationsListModal .unRead {
    background-color: #596c7d;
    color: #FFF;
}
.unRead a, .unRead a:link, .unRead a:visited {
    text-decoration: none;
    color: #91efcc !important;
}

.notificationContent a, .notificationContent a:link, .notificationContent a:visited {
    text-decoration: none;
    color: #5b3a88;
}

.NotificationUpdate a, .NotificationUpdate a:link, .NotificationUpdate a:visited {
    text-decoration: none;
    color: #91efcc;
}