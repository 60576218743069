.bos-login-container {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 99;
    backdrop-filter: blur(7px);
}
.bos-login-title-container {
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    background-color: #303e67;
    color: #FFFFFF;
    padding: 15px;
    padding-bottom: 0px;
}
.bos-login-options-container {
    text-align: center;
    margin: 7rem 0px;
}
.bos-login-options-container .bos-login-option {
    display: inline-block;
    border: 1px solid #273846cc;
    border-radius: 17px;
    color: #273846cc;
    cursor: pointer;
    transition: all 0.4s ease 0s;
}
.bos-login-options-container .bos-login-option:hover {
    background-color: #ffffff;
    transform: scale(1.1);
}
.bos-login-options-container .bos-login-option img {
    width: 300px;
    height: auto;
    margin-bottom: 55px;
}
.bos-login-options-container .bos-login-option label {
    font-size: 1em;
    display: block;
    padding-top: 17px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 10px 20px;
    cursor: pointer;
}
.bos-login-options-container .bos-login-option:hover label {
    background: #303e67;
    color: #FFFFFF;
    text-decoration: none;
}