@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* .car-management-table{
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 300px !important;
    display: block;
}
.car-management-table::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
.car-management-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.car-management-table::-webkit-scrollbar-thumb {
	background-color: #303e67;
	outline: 1px solid #303e67;
} */

.car-management-table,
.car-management-table tr td {
  font-size: 12px !important;
  font-family: "Poppins";
  border: 1px solid #dee2e64e;
  padding: 0;
}
.car-management-table tr th {
  background-color: #3e4c6d;
  color: white !important;
  white-space: nowrap;
  /* border: none !important; */
}
.car-management-table td button {
  color: #3e4c6d !important;
}
.btn-add-new-car {
  margin-bottom: 15px;
  border: none !important;
  font-size: 12px;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  text-align: center;
}
.car-management-table td button,
.car-management-table tr th {
  position: sticky;
  top: -1px;
  font-size: 12px;
  letter-spacing: 1px !important;
  font-weight: 600 !important;
  border: 1px solid #dee2e64e !important;
  text-align: center;
}
.car-management-table tr:hover {
  background-color: #f5f5f5 !important;
}
.car-management-table td button {
  border: none !important;
  padding: 0;
}
.car-management-table td .column-text {
  margin: 0;
}
.car-management-table td .column-text .car-id .fa {
  padding-left: 3px;
  padding-top: 3px;
}
.car-management-table td .column-text .car-id .fa,
.car-management-table td .column-text .car-id {
  color: blue;
  font-size: 12px !important;
  text-decoration: underline;
  float: right;
}
.car-management-table td.disabled {
  background-color: #3e4c6d1f;
  color: black;
}
.car-management-table td.car-info-deal-column {
  background-color: #489aff52;
}
.car-management-table td.car-info-plate-column {
  background-color: #48d8ff52;
}
.car-management-table td {
  padding: 5px 7px !important;
  white-space: nowrap;
  text-align: center;
}
.car-management-table td .limit-text {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.car-management-table td input:focus {
  border: none !important;
  box-shadow: none;
}

.car-management-table td select,
.car-management-table td input {
  /* border: 2px solid rgba(77, 77, 253, 0.46) !important; */
  border: none;
  margin: 0 !important;
  padding: 0rem;
  font-size: 12px !important;
}
.car-management-table td .input-group {
  width: 150px !important;
}
.car-management-table td .input-group-text {
  background: transparent !important;
  border: none;
}

.car-management-table td .fa-exclamation-circle{
  color: red !important;
  font-size: 14px;

}
.car-management-table td.invalid-value select,
.car-management-table td.invalid-value input{
  background-color: #ff000005;
}
.car-management-table td.invalid-value{
  background-color: #ff000056;
  border: 1px solid red;
}
.car-management-table td.valid-value select,
.car-management-table td.valid-value input{
  background-color: #00800000;
}
.car-management-table td.valid-value {
  background-color: #00800063;
}

.bos-car-attr-modal label,
.bos-car-attr-modal .modal-header {
  text-transform: capitalize;
}

.bos-manage-plates-table-wrapper{
  max-height: 35vh;
  overflow: auto;
}
.bos-manage-plates-table{
  margin-top: 7px;
  text-align: center;
}
.bos-manage-plates-table th{
  padding: 5px 10px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 75% !important;
}
.bos-manage-plates-table td{
  border: 1px solid #dee2e6 !important;
  padding: 5px!important;
  font-size: 85% !important;
}
.bos-manage-plates-table tr{
  margin: 0px !important;
}

.bos-manage-plates-table-wrapper::-webkit-scrollbar,
.car-management-table::-webkit-scrollbar {
	width: 7px;
  height: 10px;
}
   
.bos-manage-plates-table-wrapper::-webkit-scrollbar-track,
.car-management-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f2f3f59c;
}

.bos-manage-plates-table-wrapper::-webkit-scrollbar-thumb,
.car-management-table::-webkit-scrollbar-thumb {
	background-color: #adadad;
	outline: 1px solid #adadad;
  border-radius: 13px;
}

.bos-manage-plates-item.selected,
.bos-manage-plates-item:hover{
  background-color: #4662a27e;
  color: black;
  /* font-weight: 500; */
}