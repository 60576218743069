@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.NewTransaction{
	color:#273846cc;
	font-family: 'Rubik', 'San serif';
}
.NewTransaction .modal{
	background-color:#273846cc;
}
.modal-content{
    padding: 20px;
}
.form-group {
    margin-bottom: 2rem;
}
.form-control{
    border: 1px solid #d7dce2;
}
.categoryOption{
    background-color: rgba(239, 241, 251, .31);
    border: none;
    color: #A143FF;
    font-weight: 500;
    font-size: 16px;
    margin-right: 7px;
}
.categoryOption:hover {
    color: #fff;
    background-color: #000;
    border-color: transparent;
}
.categoryOption.active,.categoryOption:not(:disabled):not(.disabled):active, .categoryOption:not(:disabled):not(.disabled).active, .show > .categoryOption.dropdown-toggle {
    color: #fff;
    background-color: #a143ff;
    border-color: transparent;
}
.modal-dialog {
    max-width: 600px;
}