@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.bos-listing-container,
.bos-listing-container tr td {
    font-size: 12px !important;
    font-family: "Poppins";
}

.bos-listing-container .card{
    box-shadow: 3px 3px 9px 2px hsl(0deg 0% 79% / 21%);
    border: none;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.bos-listing-container .table th {
    text-transform: uppercase;
    color: rgba(42,53,84,.52);
    font-size: .8em;
    font-weight: 500;
    /* vertical-align: middle; */
    letter-spacing: .005em;
}

.bos-listing-container .table th, .table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}
.bos-listing-container .table td {
    font-size: 12px !important;
}

.bos-listing-container .table tr {
    border-bottom: 1px solid hsla(0,0%,79.2%,.21);
    margin: 10px;
    color: rgba(39,56,70,.8);
}

.bos-listing-container .table .bos-table-td-left-box i{
    font-size: 1.25rem; 
    margin-top: 1.25rem;
}
.bos-listing-container .table .bos-table-td-left-box{
    text-align: center;
    float: left;
    padding-right: 10px;
}
.bos-listing-container .table .bos-table-td-right-box{
    float: left;
    text-align: left;
    padding-left: 10px;
    border-left: 1px solid #e1e1e1;
}
.bos-listing-container .table .bos-table-td-right-box-actions-wrapper{
    float: right;
    padding-top: 12px;
    padding-right: 1.5rem;
}

.bos-listing-container .table .bos-table-td-right-box-actions-wrapper ul{
    padding: 0;
    margin: 0;
}
.bos-listing-container .table .bos-table-td-right-box-actions li:not(:first-child){
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.bos-listing-container .table .bos-table-td-right-box-actions li {
    display: inline-block;
    padding-right: 10px;
}
/* .bos-listing-container .table .bos-table-td-right-box-actions li .fa{
    font-size: 18px;
} */