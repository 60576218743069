@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.bos-object-detail-container {
    font-family: "Poppins", "Rubik", "San serif" !important;
    color: #000;
    font-size: 12px;
    background-color: #f2f3f6;
    min-height: 50vh;
}
.bos-object-detail-container .bos-object-detail-header {
    background-color: #e0e0e0;
    padding: 5rem 1rem 0rem 1rem;
}
.bos-object-detail-container .bos-object-detail-header .box-object-icon {
    background-color: #c6cbde;
    border: 7px solid #f2f3f6;
    padding: 14px 16px;
    border-radius: 50%;
    position: relative;
    bottom: -15px;
}
.bos-object-detail-container .bos-object-detail-header .bos-object-title-wrapper label {
    font-size: 24px;
    font-weight: 500;
    /* margin-bottom: 0px !important; */
}
.bos-object-detail-container .bos-object-detail-header .bos-object-property-wrapper label {
    margin-bottom: 12px !important;
}
.bos-object-detail-container .bos-object-detail-header .bos-object-property-wrapper {
    align-self: flex-end;
}
.bos-object-detail-container .bos-object-detail-body {
    padding: 35px 0px 20px 0px;
}

.bos-object-detail-container .bos-object-detail-body .nav-link {
    font-weight: 500;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #c6cbde;
    text-transform: uppercase;
    padding: 0.5rem 2.25rem !important;
}

.bos-object-detail-container .bos-object-detail-body .nav-link.active {
    background-color: #fff;
    color: #000;
    border: 1px solid #dadada;
}

.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content {
    width: 100%;
    background-color: #fff;
    /* max-height: 50vh; */
    max-height: 80vh;
    overflow-y: auto;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .container-fluid {
    padding: 35px 45px;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .box-object-property-input.form-control[multiple] {
    min-height: 225px;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .box-object-property-input:disabled {
    background-color: #fff;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .bos-object-section {
    background-color: #f0f0eb;
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 10px;
    box-shadow: 0 13px 10px 1px rgb(0 0 0 / 25%);
    -webkit-box-shadow: 0 13px 10px 1px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 13px 10px 1px rgba(0, 0, 0, .25);
    margin: 0px 15px 25px 0px;
}

.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper label:not(.toggle-switch-label) {
    opacity: .65;
    display: block;
    letter-spacing: .75px;
    margin-top: 1rem;
    margin-bottom: 0px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper label:not(.toggle-switch-label) i {
    float: right;
    margin-right: 0.25rem;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table a:hover {
    color: #0056b3;
    text-decoration: underline;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table {
    margin: 2rem 0rem;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table tr {
    border-bottom: 1px solid hsla(0, 0%, 79.2%, .21);
    margin: 10px;
    color: rgba(39, 56, 70, .8);
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table thead th {
    border: none;
    opacity: 0.5;
    text-transform: uppercase;
    font-size: 80%;
}
.bos-object-detail-container .bos-object-detail-body .bos-object-nav-content .bos-object-section-wrapper .table td {
    font-size: 12px !important;
    max-width: 700px;
}
.bos-object-detail-container .bos-listing-container .table th {
    color: black;
}