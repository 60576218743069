@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.form-control:disabled {
    background-color: #DCDCDC !important;
}
.driver-detail {
    color: #525f7f;
    font-family: 'Poppins', 'Rubik', 'San serif' !important;
    font-size: 13px;
}
.driver-detail .badge {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 400;
}
.driver-detail .badge.black {
    background-color: #263456;
    color: #FFF;
}
.driver-detail .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}
.driver-detail .badge.danger {
    background-color: #FF0000;
    color: #FFF;
}
.driver-detail .badge.warning {
    background-color: #FFA500;
    color: #FFF;
}
.driver-detail .danger {
    color: #FF5661
}
.driver-detail .success {
    color: #02BD7D
}
.driver-detail .black {
    color: #263456
}
.driver-detail a.ghost, .driver-detail .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.driver-detail .btn.primary {
    background-color: #525f7f;
    color: #FFF;
    border-color: transparent;
}
.driver-detail .btn.success {
    background-color: #02BD7D;
    color: #FFF;
    border-color: transparent;
}
.driver-detail-container .info-container {
    background-color: #F0F0EB;
    padding: 2rem;
    margin-bottom: 2rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -webkit-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
    -moz-box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 14%);
}
.driver-detail-container .info-container.set-max-height {
    max-height: 250px;
    overflow-y: scroll;
}
.balance-box-wrapper {
    height: 120px;
    width: 533px;
    border-radius: 10px;
    margin: 15px;
    background-color: #FFFFFF;
    justify-content: space-evenly;
    padding: 2rem;
    border-radius: 10px;
    border-top: solid 0px #C9C9C9;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.25);
}
.balance-box-wrapper-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #949494;
}
.balance-box-wrapper-icon {
    line-height: 51.75px;
    font-weight: 400;
    font-size: 45px;
}
.input-alternative-box {
    height: 35px;
    width: 100%;
    background-color: #DCDCDC;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}
.driver-header {
    font-family: 'Poppins';
    display: flex;
    background-color: #E0E0E0;
    color: black;
    margin-bottom: 54px;
    padding: 0px 20px 10px 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    position: relative;
}
.driver-header .driver-id-wrapper {
    background-color: #2DAFD3;
    color: white;
    top: 15%;
    right: 0px;
    padding: 0.25rem 1.75rem 0.25rem 1.5rem;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    position: absolute;
}
.driver-header .driver-header-icon {
    background-color: #1B4D7E;
    color: white;
    width: 40px;
    height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.driver-header .driver-header-label {
    font-family: 'Poppins';
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    font-style: normal;
    cursor: pointer !important;
}
.driver-header .driver-header-label:hover {
    text-decoration: underline;
}
.driver-header .driver-header-input {
    /* min-width: 270px !important; */
    background-color: white;
}
.driver-header .btn {
    font-size: 0.85em;
}
.driver-header .btn.ghost:hover, .driver-header .btn.ghost:focus, .driver-header .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.driver-header .btn.ghost {
    background-color: transparent;
    color: #525f7f;
    border: 1px solid #525f7f;
    border-radius: 4px;
    padding: 4px 8px;
    text-decoration: none;
}
.driver-header div {
    align-self: flex-end;
}
.driver-header .driver-image {
    border: 5px solid #f2f3f6;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 40px;
    background-color: #C6CBDE;
}
.driver-name {
    font-weight: 500;
    line-height: 25px;
    margin-right: auto;
    margin-left: 0.5em;
    /* position: absolute;
    left: 155px;
    bottom: 0px; */
    font-size: 1.25em;
}
.driver-header .badge.success {
    background-color: #02BD7D;
    color: #FFF;
}

.driver-header .badge.black {
    background-color: #263456;
    color: #FFF;
}

.driver-header .header-detail {
    font-size: 14px;
    margin-bottom: 0;
}
.tab-container {
    width: 100%;
    background-color: #FFFFFF;
    padding: 2.5rem;
    border: 1px solid #DADADA;
    min-height: 150px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.tab-container .blue-highlighted-header {
    background-color: #2DAFD3;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.tab-container .green-highlighted-header {
    background-color: green;
    color: white;
    font-size: 16px !important;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
}
.driver-detail-container .tab-container .info-container button {
    background-color: #263456;
    border-radius: 6px;
    padding: 6px 10px;
}
.driver-detail-container .tab-container .info-container button:hover {
    background-color: #f2f3f6;
    color: #000000
}
.driver-detail .nav-item {
    /* font-size: 14px;
    font-weight: 400; */
    text-transform: uppercase;
    font-family: "Poppins";
    /* color: #000000; */
    text-align: center;
    /* margin-right: 0.75rem; */
    border: none;
    /* border-radius: 0px !important; */
    background-color: transparent;
    /* border: 1px solid #DADADA; */
    /* border-bottom: 0px; */
    /* border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important; */
    /* background-color: #F0F0EB; */
}
.driver-detail .nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    color: #1B4D7E;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    font-weight: 500;
    color: #1B4D7E;
    border: none;
    border-bottom: 3px solid #1B4D7E;
    background-color: transparent;
}

.driver-detail .nav-tabs .nav-item .nav-link:hover {
    /* color: #1B4D7E;
    border: none;
    border-radius: 0px !important; */
    border-bottom: 3px solid #1B4D7E;
}
.driver-detail .nav-tabs .nav-item .nav-link {
    cursor: pointer;
    font-size: 0.85em;
    padding: 0.25rem 1.25rem !important;
    border-radius: 0px !important;
    border-right: 1px solid #DADADA;
}

.driver-detail .nav-item:nth-child(n+2):after {
    margin-left: 2rem;
}
.driver-detail-container .info-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    line-height: 18.75px;
    color: #000000;
}
.tab-container label {
    opacity: 0.85;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500;
    color: #000000;
}
.driver-detail-container .tab-container .info-container > .row {
    margin-bottom: 1rem;
}
.driver-detail-container .tab-container .info-container .status-stage {
    display: flex;
    flex-direction: column;
}
.driver-detail-container .tab-container .info-container .row:last-child {
    margin-bottom: 0rem;
}
.driver-detail-container .tab-container .info-container input[type="text"]:disabled {
    background-color: #FFFFFF;
}
.zero-padding {
    padding: 0px
}
.driver-detail .tab-container:last-child {
    margin-bottom: 0rem;
}
.driver-detail .tab-container table {
    width: 100%;
}
.driver-detail .tab-container table thead {
    background-color: #F0F0EB;
    color: #000000;
}
.driver-detail .tab-container table tr {
    border: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
}
.driver-detail .tab-container table th, table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 15px;
}
.driver-detail .tab-container table th {
    font-size: 15px;
    font-weight: 500;
}
.driver-detail .tab-container table td {
    padding-left: 20px;
}
.driverHeader {
    border-radius: 18px;
    border: 1px solid #E0E0E0;
    background: #E0E0E0;
    min-height: 20rem;
    height: 25rem;
    margin-bottom: 15px;
}
.driver-detail .btn.ghost:hover, .driver-detail .btn.ghost:focus, .driver-detail .btn.ghost:active {
    background-color: #263456;
    color: #FFF;
    border-color: transparent;
}
.toggle-switch {
    position: relative;
    width: 60px;
    padding: 0px 9px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    opacity: 0.85;
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.1s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 24px;
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    color: white;
    font-weight: 500;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: "";
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #DC3546;
    color: #fff;
}
.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 3px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 25px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.1s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 10px;
}
.confirm, .confirm:hover {
    background-color: #dc3545;
}
.cancel, .cancel:hover {
    background-color: rgb(27, 77, 126);
}
.driver-detail-container .change-view {
    margin-bottom: 15px;
}
.driver-detail i.switch {
    font-size: 25px;
    border: none;
}
.rental-container .car-agreement {
    border-bottom: 1px solid #D6D6D6 !important;
    padding: 0.5rem 1.5rem !important;
    display: flex !important;
    flex-direction: row !important;
}
#more-agreements {
    margin-top: 10px !important;
    cursor: pointer !important;
    position: absolute;
    right: 20px;
}
.rental-container .car-agreement.active,
.rental-container .car-agreement:focus,
.rental-container .car-agreement:hover {
    background-color: #F0F0EB !important;
}
.rental-container .car-agreement span {
    align-self: center !important;
    justify-content: center;
    align-items: center;
}
.rental-container .car-agreement h4 {
    font-family: "Poppins" !important;
    padding-left: 1rem !important;
    font-weight: 500 !important;
    padding-top: 0.55rem !important;
    max-width: 200px !important;
    font-size: 15px !important;

}

.charge-day-history {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}


.sidebar-listing-wrapper-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar-listing-wrapper-text {
    margin-left: 10px;
}
.sidebar-listing-wrapper-text p {
    font-size: 15px;
    font-weight: 600;
    font-family: "Poppins";
    padding: 5px !important;
    margin: 0px !important;
}
.sidebar-listing-wrapper-text small {
    padding: 5px !important;
}

.submit-btn {
    background-color: #0B80F9;
    border: 1px solid #0B80F9;
    border-radius: 7px;
}
.submit-btn:hover {
    color: #41505d;
    background-color: transparent;
    border: 1px solid #41505d;
}
.driverAddress .btn {
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400 !important;
}
.driverAddress .btn.btn-primary {
    background-color: #0B80F9;
}
.driverAddress .modal-content {
    background-color: white;
    border-radius: 0px;
    color: black;
}
.driverAddress .modal-content .modal-body {
    max-height: 75vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.driverAddress .modal-content small,
.driverAddress .modal-content label {
    color: rgb(73, 73, 73);
    text-transform: uppercase;
    letter-spacing: 0.75px;
}
.driverAddress .modal-content .modal-header {
    justify-content: center;
}
.driverAddress .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
.driver-tracker-modal .modal-content .modal-body > .row:hover .selectButtonParent, .driver-tracker-modal .modal-content .modal-body > .row:active .selectButtonParent {
    left: 0;
    opacity: 1;
}
.tracker-actions {
    margin: 0px;
    padding: 0px;
}
.tracker-wrapper .tracker-header {
    margin-bottom: 1.2rem;
}
.tracker-actions li:not(:first-child) {
    border-left: 1px solid #e1e1e1;
    padding-left: 10px;
}
.tracker-actions li {
    display: inline-block;
}

.no-margin {
    margin-bottom: 0 !important;
}
.flex-column {
    display: flex;
    flex-direction: column;
}