.audit-search-filter {
    position: relative;
}
.audit-search-filter .search-icon {
    position: absolute;
    right: 15px !important;
    top: 9px !important;
    color: #d7dce2;
}
.audit-search-filter input {
    border-radius: 15px;
    border-width: 3px !important;
}
.search-filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: flex-start;
}
.search-filter-preview {
    border: 1px dashed #52606b;
    border-radius: 15px;
    padding: 5px 10px;
    margin-right: 5px;
}
.search-filter-preview span {
    color: #273846cc;
    text-transform: capitalize;
    border-right: 1px solid #52606b;
    padding-right: 5px;
    margin-right: 5px;
}
.search-filter-preview {
    color: #0B80F9;
}

.audit-list-button {
    color: #0B80F9 !important;
    cursor: pointer;
}
.audit-list-button:hover {
    text-decoration: underline !important;
}
.select-all-checkbox {
    text-align: center;
}

.audit-data-table-container {
    max-height: 75vh;
    overflow-y: auto;
    margin-bottom: .5rem;
    margin-top: .5rem;
    box-shadow: 3px 3px 9px 2px rgba(202, 202, 202, 0.21);
    border: none;
    border-radius: 8px;
}

.audit-data-table-container td {
    font-size: 12px !important;
}
.audit-data-table-container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.audit-data-table-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.audit-data-table-container::-webkit-scrollbar-thumb {
    background-color: #303e67;
    outline: 1px solid #303e67;
}
.audit-data-table-container .progress {
    height: 0.5vh;
}

.audit-data-table-container .table th, .table td {
    border: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.audit-data-table-container .table tr {
    border-bottom: 1px solid rgba(202, 202, 202, 0.21);
    margin: 10px;
    color: #273846cc;
}
.audit-data-table-container .table th {
    text-transform: uppercase;
    color: rgba(42, 53, 84, 0.52);
    font-size: 0.8em;
    font-weight: 500;
    vertical-align: middle;
    letter-spacing: 0.005em;
}
