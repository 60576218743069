.NotificationUpdate {
    position: fixed !important;
    top: 20px;
    min-width: 30px;
    min-height: 30px;
    right: -1000px;
    opacity: 0.1;
    margin: 0;
    z-index: 10000;
    background: #3e4c6d;
    border: none;
    border-radius: 40px 0px 0px 40px;
    color: #ffffff;
    box-shadow: 4px 4px 9px 3px rgb(205 211 226 / 68%);
    padding: 8px 20px;
    transition: right .35s ease-in-out;
}
.NotificationUpdate.show {
    right: 0;
    opacity: 1;
}
.NotificationUpdate p {
    margin: 0;
}
