.payment-plan.modal-dialog {
    min-width: 800px !important;
}
.payment-plan .modal-content {
    background-color: white;
    color: #525f6f;
}
.payment-plan .modal-content .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 15px;
}
.payment-plan .modal-content label {
    color: #525f6f;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 80%;
    font-weight: 600;
}
.payment-plan .modal-content p {
    margin-bottom: 0.25rem;

}
.payment-plan.modal-dialog row {
    margin-bottom: 15px !important;
}

.payment-plan .modal-content .modal-header {
    justify-content: center;
    align-items: center;
    display: flex;
}

.payment-plan .modal-content .modal-header .modal-title {
    font-weight: 400;
    font-size: 1.75em;
    text-transform: uppercase;
}

.payment-plan-modal-primary-btn {
    background-color: #1B4D7E;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
}
.payment-plan-modal-secondary-btn {
    background-color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: black;
    cursor: pointer;
}
.payment-plan-modal-danger-btn {
    background-color: red;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    border: #ffffff;
}

.payment-plan .modal-content .toggle-section-heading i {
    float: right;
    margin-top: 0.25rem;
}
.payment-plan .modal-content .toggle-section-heading.active {
    background-color: #303e67;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    color: #ffffff;
}
.payment-plan .modal-content .toggle-section-heading {
    display: block;
    color: #525f6f;
    background-color: #D6D6D6;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-size: 1em;
    margin-bottom: 1rem;
}