.more-info-tooltip {
    opacity: 1 !important;
}
.more-info-tooltip .tooltip-inner {
    padding: 0.5rem 1rem;
    text-align: left;
    background-color: #F2F2F2;
    color: black;
    border: 1px solid #d7dce2;
    font-size: 80%;
    max-height: 80px;
    overflow-y: auto;
}